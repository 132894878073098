<template>
  <div id="app">
    <Home msg=""/>
    <Chat></Chat>
  </div>
</template>

<script>
import Home from './components/Home.vue'
import Chat from './components/Chat.vue'
import axios from 'axios'
// import VConsole from 'vconsole'
export default {
  name: 'App',
  components: {
    Home,
    Chat
  },
  data() {
    return {
    }
  },
  created () {
    // 在页面加载时读取sessionStorage里的状态信息
    if (sessionStorage.getItem('store')) {
      this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem('store'))))
      axios.defaults.headers.common['lang'] = this.$store.state.languageType
    }
    // 在页面刷新时将vuex里的信息保存到sessionStorage里
    window.addEventListener('beforeunload', () => {
      sessionStorage.setItem('store', JSON.stringify(this.$store.state))
    })
// const vConsole = new VConsole()

  },
  mounted() {
    window.onload = function () {
      // 阻止双击放大
      var lastTouchEnd = 0;
      document.addEventListener('touchstart', function (event) {
        if (event.touches.length > 1) {
          event.preventDefault();
        }
      });
      document.addEventListener('touchend', function (event) {
        var now = (new Date()).getTime();
        if (now - lastTouchEnd <= 300) {
          event.preventDefault();
        }
        lastTouchEnd = now;
      }, false);

      // 阻止双指放大
      document.addEventListener('gesturestart', function (event) {
        event.preventDefault();
      });
    }
  },
  methods: {
  }
}
</script>

<style lang="scss">
body, div, span, header, footer, nav, section, aside, article, ul, dl, dt, dd, li, a, p, h1, h2, h3, h4, h5, h6, i, b, textarea, button, input, select, figure, figcaption {
  padding: 0;
  margin: 0;
  list-style: none;
  font-style: normal;
  text-decoration: none;
  border: none;
  outline: none;
  font-weight: normal;
  font-family: 'Microsoft Yahei';
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
}
// k线
#kline_container iframe {
  height: 400px!important;
  background: #fff!important;
}
#kline_container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  height: 400px;
  border-radius: 0px;
  padding-right: 4px;
  background: #fff!important;
}
#kline_container.hidden,
#depth_chart.hidden {
  z-index: -100;
}
#depth_chart {
  position: absolute!important;
  top: 0;
  left: 0;
  width: 100%;
  height: 400px!important;
  border-radius: 6px;
  overflow: hidden;
  background: #fff;
}
#kline_container.hidden, #depth_chart.hidden {
  z-index: -100;
}
#depthGraph_cover {
  width: 100%!important;
}
.el-message {
  top: 20px !important;
}
.DINPro {
  font-family: 'DINPro-Regular';
}
.Montserrat {
  font-family: 'Montserrat-Black';
}
.select-currency {
  .el-select-dropdown__item {
    padding: 0 12px !important;
    height: 40px;
  }
  .currency-img {
    height: 34px;
    display: flex;
    align-items: center;
    img {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      margin-right: 12px;
    }
  }
}
.el-tabs {
  .el-tabs__item {
    box-shadow: none !important;
  }
}
.v-modal {
  opacity: 1 !important;
  background: rgba(0,0,0,0.5)!important;
}
.withdrawal-address-value {
  display: none;
}
.close-position-tooltip,.el-tooltip__popper.is-dark{
  background:rgba(104,112,123,0.8) !important;
  font-size: 14px;
  color: rgba(255,255,255,0.8) !important;
  max-width: 800px;
}
.close-position-tooltip[x-placement^=top] .popper__arrow {
  border-top-color: rgba(104,112,123,0.4) !important;
}
.close-position-tooltip[x-placement^=top] .popper__arrow:after {
  border-top-color: rgba(104,112,123,0.4) !important;
  /*filter:alpha(opacity=50);
  -moz-opacity:0.5;
  -khtml-opacity: 0.5;
  opacity: 0.5;*/
}
.el-tooltip__popper[x-placement^=top] .popper__arrow {
  border-top-color: rgba(104,112,123,0.4) !important;
}
.el-tooltip__popper[x-placement^=top] .popper__arrow:after {
  border-top-color: rgba(104,112,123,0.4) !important;
  /*filter:alpha(opacity=50);
  -moz-opacity:0.5;
  -khtml-opacity: 0.5;
  opacity: 0.5;*/
}
</style>

module.exports = {
  // 公共
  common: {
    title: 'S6'
  },
  // 头部
  header: {
    market: '行情',
    coincurrencytrading: '现货交易',
    ustandardcontract: 'U本位合约',
    currencystandardcontract: '币本位合约',
    financialservices: '金融理财',
    login: '登录',
    register: '注册',
    usercenter: "用户中心",
    grzl: '个人总览',
    zcgl: '资产管理',
    jygl: '交易管理',
    lcgl: '理财管理',
    tgzx: '推广中心',
    kqzx: '卡券中心',
    zd: '账单',
    out: '退出登录',
    appdownlaod: 'APP下载',
    downloadapp: "扫码下载iOS&Android APP",
    lang: '中文简体'
  },
  // 底部
  footer: {
    join: '加入官方telegram频道',
    gsjj: '关于',
    notice: '公告中心',
    bzzx: '帮助中心',
    tk: '条款',
    fwxy: '服务协议',
    ysxy: '隐私协议',
    mzsm: '免责声明',
    other: '其他',
    rate: '费率标准',
    api: 'API'
  },
  // 聊天
  chat: {
    title: '联系客服',
    sendBtn: '发送',
    officialNews: '官方消息',
    uploadFail: '上传失败',
    photoAlbum: '相册',
    placeholder: '请输入您的信息',
    yesterday: '昨天',
    customerWithdraw: '对方撤回了一条消息'
  },
  // 登录
  login: {
    login: {
      title: '登录',
      username: '邮箱 / 手机号 / 用户名',
      placeholder: '请输入',
      noRegister: '没有账户？去',
      registerName: '注册',
      forgetPwd: '忘记密码',
      validateUserName: '邮箱/手机号/用户名不能为空',
      validatePwd: '密码不能为空',
      pwdLabel: '密码',
    },
    forgetPwd: {
      title: '重置密码',
      forgetPwdAfter: '重置登录密码后，24小时内禁止提币和OTC划转',
      username: '邮箱 / 手机 / 用户名',
      placeholder: '请输入',
      btnNext: '提交',
      btnCancel: '取消',
      securityVerification: '安全验证',
      mailboxVerification: '邮箱验证码',
      getVerificationCode: '获取验证码',
      mobileVerification: '手机验证码',
      googleAuthenticator: '谷歌验证码',
      setNewPassword: '设置新密码',
      newPassword: '新密码',
      newPwdPlaceholder: '请输入6-20位密码',
      confirmPassword: '确认密码',
      againPlaceholder: '请再次输入',
      determine: '确认',
      pleaseVerificationCode: '请输入正确的6位验证码',
      sixVerificationCode: '请输入6位数字验证码',
      pleasePwdVerificationCode: '请输入密码',
      pwdTipsVerificationCode: '密码不能为纯英文或数字，不能包含空格',
      confirmPwdVerificationCode: '请输入确认密码',
      twicePwdVerificationCode: '两次输入密码不一致!',
      pleaseUsernameVerificationCode: '邮箱/手机/用户名不能为空',
      second: 's',
      errorTip: '账号未绑定谷歌或邮箱，无法重置密码',
      successVerify: '发送验证码成功',
      pwdLength: '密码长度为 6-20 位字符',
      inputYourPhone: '输入您的手机号',
      inputYourEmail: '输入您的邮箱',
      receivedVerifyCode: '收到的验证码'
    },
    registerAccount: {
      registered: '注册账号',
      username: '用户名',
      mailbox: '邮箱',
      cellPhone: '手机号',
      getVerificationCode: '获取验证码',
      invitaCode: '是否有邀请码？',
      inputInvite: '邀请码 (选填)',
      register: '注册',
      existingAccount: '已有账户？去',
      login: '登录',
      placeholder: '请输入',
      inputUsername: '请输入用户名',
      second: 's',
      inputPhone: '请输入手机号',
      inputVerificationCode: '请输入验证码',
      inputSixVerificationCode: '请输入6位数字验证码',
      sixVerificationCode: '6-20位密码',
      inputPwd: '请输入密码',
      pwdNo: '密码不能为纯英文或数字，不能包含空格',
      anginPwd: '请输入确认密码',
      pwdAtypism: '两次输入密码不一致!!',
      againInputPwd: '再次输入密码',
      inputEmail: '请输入邮箱',
      emailIncorrectFormat: '邮箱格式不正确',
      emailVerificationCode: '邮箱验证码',
      phoneVerificationCode: '短信验证码',
      pwdLength: '密码长度为 6-20 位字符',
      successVerify: '发送验证码成功',
      registerSuccess: '注册成功',
      pwd: '密码'
    },
    SecondaryVerification: {
      SecondaryVerification: '安全验证',
      email: '邮箱',
      phone: '手机号',
      google: '谷歌',
      placeholder: '请输入',
      getVerificationCode: '获取验证码',
      login: '确认',
      correctVerificationCode: '登录失败，请输入正确的验证码',
      inputVerificationCode: '请输入正确的6位验证码',
      inputSixVerificationCode: '请输入正确的6位验证码',
      emailVerificationCode: '邮箱验证码',
      phoneVerificationCode: '手机验证码',
      googleVerificationCode: '谷歌验证码',
      second: 's',
      successVerify: '发送验证码成功',
      successLogin: '登录成功'
    }
  },
  // 首页
  homePage: {
    oneLabel: 'S6.com全球领先数字资产及衍生品交易平台',
    twoLabel: '合约交易火爆进行中，三大福利大放送',
    placeholder: '请输入用户名/邮箱/手机号',
    register: '注册',
    immediateTransa: '立即交易',
    symbolLabel: '交易对',
    closeLabel: '最新价',
    chgLabel: '24H涨跌幅',
    quotationLabel: '行情',
    operationLabel: '操作',
    trans: '交易',
    moreQuotation: '查看更多行情',
    turnover: 'S6，近24小时成交量',
    dollar: '美元',
    platform: 'S6 提供数百种币币和合约交易对，以满足多样化的交易需求。平台在50个国家和地区中有超过一百万用户，是全球领先的数字资产服务平台。',
    nationalDistribution: '用户国家分布',
    numberOfUsers: '用户数',
    userNum: '1500万+',
    serviceSupport: '服务支持',
    whenever: '随时随地，多平台终端交易',
    compatible: '多终端完美兼容，随时满足各种场景的交易需求',
    realTimeQuotation: '随时查看实时行情',
    relaxed: '轻松买卖BTC，ETH，DOGE和其他数字资产',
    obtain: '获取您关注的加密货币价格变化预警',
    viewPrice: '查看比特币现货、期货、期权价格',
    comparativePrice: '比较各交易所加密货币价格',
    open: '开启您的数字货币之旅',
    quickPurchase: '快捷购买数字货币',
    morePaymentMethod: '使用多种支付方式交易数字货币，安全快捷',
    transRate: '最优的交易费率',
    service: '优惠的交易费率，富有竞争力的VIP权益，享受最优品质的服务',
    securityGuarantee: '可信赖的安全保障',
    avoidRisk: '我们复杂的安全措施和SAFU基金保护您的数字资产免受所有风险。',
    createAccount: '10s快速创建您的账号，开启20倍收益杠杆交易，100倍收益合约交易'
  },
  // 用户中心
  pageUCenter: {
    pageUCenter: {
      personalOverview: '个人总览',
      securitySetting: '安全设置',
      assetManagement: '资产管理',
      transactionManagement: '交易管理',
      financialManagement: '理财管理',
      promotionCenter: '推广中心',
      cardCenter: '卡券中心',
      billManagement: '账单',
      errorTip: '暂未开通，敬请期待',
    },
    personalOverview: {
      personalOverview: '个人总览',
      low: '低',
      high: '高',
      assetValuation: '资产估值',
      safetyLevel: '安全等级',
      lower: '您的账户安全等级为低级，建议完善',
      intermediate: '您的账户安全等级为中级，建议完善',
      senior: '您的账户安全等级为 高 级。',
      perfect: '立即完善',
      goodsStock: '现货',
      positionValuation: '持仓估值',
      contract: '合约',
      money: '理财',
      loginHistory: '用户登录历史',
      display: '每页',
      recordsTotal: '条，总共',
      records: '页',
      loginTimeLabel: '登录时间',
      loginTypeLabel: '登录方式',
      loginIpLabel: '登录IP',
      copySuccess: '内容复制成功',
      copyError: '内容复制失败',
      headPortrait: '修改头像',
      editAccount: '修改账号',
      editAccountInfoSuccess: '修改账号信息成功',
      username: '用户名',
      inputEnter: '请输入',
      nickName: '昵称',
      signature: '个人签名',
      reset: '重置',
      search: '搜索',
      inputNickName: '请输入昵称，昵称需要2-10位字符',
      inputSignature: '请输入个人签名，签名需2-30字符',
      nickNameVerify: '昵称需要大于2或小于10个字',
      signatureVerify: '个人签名需要大于2或小于20个字',
      placeholderNickName: '请输入账号昵称',
      placeholderSignature: '请输入个人签名',
      cancel: '取消',
      save: '保存',
      uploadImgTip: '支持png/jpg格式，图片大小不超过2M',
      uploadImgTipSuccess: '图片上传成功',
      uploadImgTipFail: '图片上传失败，请重新上传',
    },
    securitySetting: {
      securitySetting: '安全设置',
      loginPwd: '登录密码',
      loginTitle: '登录时使用，请妥善保管您的登录密码，建议您定期修改',
      edit: '修改',
      bindingPhone: '绑定手机',
      bindingPhoneTitle: '用户登录、提币、修改安全设置时进行安全验证',
      binding: '绑定',
      bindingEmail: '绑定邮箱',
      googleAuthent: '谷歌认证',
      closeGoogleAuthent: '关闭',
      authenticationToken: '请扫描下方二维码或输入下述密钥添加验证令牌',
      key: '密钥：',
      determine: '确定',
      getVerificationCode: '获取验证码',
      copySuccess: '内容复制成功',
      copyError: '内容复制失败',
      inputVerif: '请输入验证码',
      inputSixVerif: '请输入6位数字验证码',
      inputPwd: '请输入密码',
      inputPwdTip: '密码不能为纯英文或数字，不能包含空格',
      inputPhone: '请输入手机号',
      inputEmail: '请输入邮箱',
      inputEmailNo: '邮箱格式不正确',
      phoneVerif: '手机验证码',
      noteVerify: '请输入手机验证码',
      emailVerify: '邮箱验证码',
      emailVerifyCode: '请输入邮箱验证码',
      googleVerify: '谷歌验证',
      googleVerifyCode: '请输入谷歌验证码',
      oldLoginPwd: '请输入原登录密码',
      newLoginPwd: '请输入新登录密码',
      confirmPwd: '请输入确认密码',
      confirmNewPwd: '请输入确认新密码',
      twicePwdNo: '两次输入密码不一致!',
      inputNewPhone: '请输入新手机号',
      bindingGoogle: '绑定谷歌验证器',
      editGoogle: '修改谷歌验证器',
      closeGoogle: '二次验证',
      editPhone: '修改手机',
      editLoginPwd: '修改登录密码',
      second: 's',
      primaryLoginPwd: '原登录密码',
      latestLoginPwd: '新登录密码',
      inputSixPwd: '请输入6-20位密码',
      confirmNewPassword: '确认新密码',
      inputNewPwd: '请再次输入新密码',
      newPhone: '手机号',
      newNoteVerify: '手机验证码',
      oldPhoneVerify: '手机验证码',
      phone: '手机号',
      shortMessageVerify: '手机验证码',
      email: '邮箱',
      newGoogle: '新谷歌验证',
      newGoogleVerify: '请输入新谷歌验证码',
      oldGoogle: '原谷歌验证',
      oldGoogleVerify: '请输入原谷歌验证码',
      pwdTwiceVerify: '请先绑定谷歌、手机或者邮箱', 
      googleTwiceVerify: '您只绑定了谷歌验证作为二次验证，因此不可关闭',
      successVerify: '发送成功',
      pwdLength: '密码长度为 6-20 位字符',
      pwdFirst: '密码第一个为字母',
      msgBindingGoogle: '绑定谷歌验证器成功',
      msgEditGoogle: '修改谷歌验证器成功',
      msgCloseGoogle: '关闭谷歌验证器成功',
      msgBindingEmail: '绑定邮箱成功',
      msgBindingPhone: '绑定手机成功',
      msgEditPhone: '修改手机成功',
      msgEditLoginPwd: '登录密码修改成功',
      payPassword: '支付密码',
      withdrawsMoneyUse: '用户提币时使用',
      reset: '重置',
      setUp: '设置',
      secondaryVerification: '二次验证',
      resetPaymentPassword: '重置支付密码',
      nextStep: '下一步',
      back: '上一步',
      inputPayPwd: '请输入新支付密码',
      payPwdVerify: '请输入6位新支付密码',
      inputConfirmPayPwd: '请输入确认新支付密码',
      inputOldPayPwd: '请输入支付密码',
      oldPayPwdVerify: '请输入6位支付密码',
      inputConfirmOldPayPwd: '请输入确认支付密码',
      setPaymentPassword: '设置支付密码',
      setPaymentPasswordSuccess: '支付密码修改成功',
      resetPaymentPasswordSuccess: '支付密码重置成功',
      paymentPassword: '支付密码',
      confirmOldPayPwdVerify: '请再次输入6位支付密码',
      newPaymentPassword: '新支付密码',
      confirmNewPaymentPassword: '确认新支付密码',
      placeholderConfirmNewPaymentPassword: '请再次输入6位新支付密码',
      cancel: '取消',
      mobileAreaCode: '手机区号',
      pleaseInput: '请输入 ',
      getVerify: ' 收到的验证码:',
      pleaseInputPhone: '请输入手机',
      getSixVerify: '所收到的六位数验证码'
    },
    // 登录
    login: {
       login: {
        title: '登录',
        username: '邮箱 / 手机号 / 用户名',
        placeholder: '请输入',
        noRegister: '没有账户？去',
        registerName: '注册',
        forgetPwd: '忘记密码？',
        validateUserName: '邮箱/手机号/用户名不能为空',
        validatePwd: '密码不能为空'
       },
       forgetPwd: {
        title: '重置密码',
        username: '邮箱/手机号/用户名',
        placeholder: '请输入',
        btnNext: '下一步',
        btnCancel: '取消',
        securityVerification: '安全验证',
        mailboxVerification: '邮箱验证',
        getVerificationCode: '获取验证码',
        mobileVerification: '手机号验证',
        googleAuthenticator: '谷歌验证',
        setNewPassword: '设置新密码',
        newPassword: '新密码',
        newPwdPlaceholder: '请输入6-20位密码',
        confirmPassword: '确认密码',
        againPlaceholder: '请再次输入',
        determine: '确定',
        pleaseVerificationCode: '请输入验证码',
        sixVerificationCode: '请输入6位数字验证码',
        pleasePwdVerificationCode: '请输入密码',
        pwdTipsVerificationCode: '密码不能为纯英文或数字，不能包含空格',
        confirmPwdVerificationCode: '请输入确认密码',
        twicePwdVerificationCode: '两次输入密码不一致!',
        pleaseUsernameVerificationCode: '请输入邮箱/手机号/用户名',
        second: 's',
        errorTip: '账号未绑定谷歌或邮箱，无法重置密码',
        pwdLength: '密码长度为 6-20 位字符 ',
        inputYourPhone: '输入您的手机号',
        inputYourEmail: '输入您的邮箱',
        receivedVerifyCode: '收到的验证码'
       }
    },
    assetManagement: {
      assetManagement: '资产管理',
      digitalCurrency: '数字货币',
      legalCurrency: '法币',
      hideSmallCurrency: '隐藏小额资产',
      searchCurrency: '搜索币种',
      noData: '暂无数据',
      currency: '币种',
      all: '全部',
      available: '可用',
      frozen: '冻结',
      valuation: '估值',
      operation: '操作',
      recharge: '充值',
      withdrawal: '提现',
      transaction: '交易',
      cancel: '取消',
      toSet: '确定',
      setPayPwd: '为保障您的资金安全，请先在个人中心—>安全设置中绑定谷歌、手机或者邮箱',
      securityVerification: '安全验证'
    },
    transactionManagement: {
      transactionManagement: '交易管理',
      spotTrading: '现货交易',
      uStandardContract: 'U本位合约',
      currencyStandardContract: '币本位合约',
      totalAssetValuation: '总资产估值',
      currentDelegation: '当前委托',
      historicalEntrustment: '历史委托',
      hideCanceled: '隐藏已撤销',
      startDate: '开始日期',
      to: '至',
      endDate: '结束日期',
      selectTransactionPair: '请选择交易对',
      selectDirection: '请选择方向',
      query: '查询',
      reset: '重置',
      noData: '暂无数据',
      orderID: '订单ID',
      orderTime: '下单时间',
      tradingPair: '交易对',
      direction: '方向',
      sellOut: '卖出',
      purchase: '买入',
      commissionPrice: '委托价格',
      entrustedQuantity: '委托数量',
      totalEntrustedAmount: '委托总额',
      operation: '操作',
      revoke: '撤销',
      averageTransactionPrice: '成交均价',
      serviceCharge: '手续费',
      status: '状态',
      rescinded: '已撤销',
      completeTransaction: '完全成交',
      completionTime: '完成时间',
      oOperatingTps: '操作提示',
      isCancelOrder: '是否确认撤销该订单？',
      cancel: '取消',
      determine: '确定',
      buy: '买入',
      sell: '卖出',
      revokeSuccess: '撤销中',
      positionValuation: '持仓估值',
      currentProfitLoss: '当前盈亏',
      profitLossRatio: '盈亏率',
      allFlat: '一键全平',
      allUndo: '一键撤销',
      isUnwindAll: '是否确认全部平仓？',
      isUnwind: '是否确认平仓此订单？',
      isUndoAll: '是否确认全部撤销？',
      isUndo: '是否确认撤销此委托单？',
      position: '持仓',
      unwindRecord: '平仓记录',
    },
    rechargeWithdrawal: {
      assetManagement: '资产管理',
      recharge: '充值',
      withdrawal: '提现',
      recharging: '充币',
      withdrawMoney: '提币',
      currency: '币种',
      selectCurrency: '请选择币种',
      available: '可用',
      availableS: '可用：',
      frozen: '冻结',
      total: '总额',
      chargingAddress: '充币地址',
      withdrawalAddress: '提币地址',
      chargingAddressTipOne: '该地址为',
      chargingAddressTipTwo: '充值地址，充值任何非',
      chargingAddressTipThree: '相关的资产将可能导致资产丢失，无法找回',
      viewAddress: '查看地址',
      selectWithdrawalAddress: '请选择提币地址',
      addAddress: '添加地址',
      withdrawalQuantity: '提币数量',
      inputWithdrawalQuantity: '请输入提币数量',
      receivedQuantity: '到账数量：',
      serviceCharge: '手续费：',
      coinChargingNotice: '充币须知',
      coinChargingNoticeTipOne: '禁止向',
      coinChargingNoticeTipTwo: '充币地址充值除',
      coinChargingNoticeTipThree: '之外的资产，任何充入',
      coinChargingNoticeTipFour: '地址的非',
      coinChargingNoticeTipFive: '资产将不可找回',
      fullStop: '；',
      coinChargingNoticeTipSix: '的区块确认数达到',
      coinChargingNoticeTipSeven: '个后，您的',
      coinChargingNoticeTipEight: '会自动转到您的账户；',
      coinChargingNoticeTipNine: '充值完成后，您可以进入充币记录页面跟踪进度。',
      transferInstructions: '转账说明',
      transferInstructionsTipOne: '24h转账额度：',
      transferInstructionsTipTwo: 'USDT（累计折合超过',
      transferInstructionsTipThree: 'USDT，转账需等待系统审核）',
      transferInstructionsTipFour: '当前选择为',
      transferInstructionsTipFive: '收款地址必须为此链上地址。',
      transferInstructionsTipSix: '单笔转账手续费',
      transferInstructionsTipSeven: '若收款地址为平台内部地址，转账免手续费。',
      transferInstructionsTipEight: '请勿向非',
      transferInstructionsTipNine: '地址转账，否则无法到账，且不可找回。',
      chargingRecord: '充币记录',
      withdrawalRecord: '提币记录',
      noData: '暂无数据',
      time: '时间',
      chainName: '链名称',
      address: '地址',
      number: '数量',
      confirmedNumber: '确认数',
      status: '状态',
      complete: '完成',
      fail: '失败',
      haveInHand: '进行中',
      operation: '操作',
      inputPaymentPassword: '二次验证',
      determine: '确定',
      forgetPaymentPassword: '忘记支付密码',
      addressReceived: '(此地址只可接收',
      rightBrackets: ')',
      defaultAddress: '默认地址：',
      chargedTimes: '已充币次数：',
      frequency: '次',
      newChargingAddress: '生成新充币地址',
      paymentAddress: '收款地址',
      edit: '修改',
      delete: '删除',
      withdrawalQuantityVerify: '请输入提币数量',
      numberVerify: '请输入数字',
      singleWithdrawalVerify: '单笔提币数量不小于',
      passwordVerify: '请输入密码',
      withdrawalAddressVerify: '请选择提币地址',
      addressRemarksVerify: '请输入地址备注',
      addressRemarks: '地址备注',
      placeholderAddressRemarks: '备注不能超过20字',
      cancel: '取消',
      operatingTips: '操作提示',
      isDeleteAddress: '是否确认删除该地址？',
      confirmDeletion: '确认删除',
      determineOperation: '确定进行本次操作吗？',
      modifyNoteName: '修改备注名',
      placeholderCommentName: '请输入新备注名',
      placeholderComment: '请输入备注',
      generateNewCurrencyRecharge: '生成新充币地址',
      placeholderNewCurrencyRecharge: '请输入新地址备注名',
      tip: '提示',
      withdrawalSuccessful: '提现成功',
      singleCurrencyWithdrawalNotGreaterThan: '单笔提币数量不能超过可用额度，',
      orAvailableQuantity: '并且在',
      to: '至',
      between: '之间',
      addAddressSuccess: '添加地址成功',
      modifyNoteNameSuccess: '修改备注成功',
      newCurrencyRechargeSuccess: '生成新充币地址成功',
      notDelete: '不可删除',
      delAddressSuccess: '删除地址成功',
      noChain: '该币种没有链',
      noRechargeCurrency: '暂无可充值币种',
      noWithdrawableCurrency: '暂无可提现币种',
      newAddress: '新地址',
      colon: '：',
      pleasePhoneVerify: '请输入手机验证码',
      pleaseEmailVerify: '请输入邮箱验证码',
      pleaseGoogleVerify: '请输入谷歌验证码',
      phoneVerify: '手机验证码',
      emailVerify: '邮箱验证码',
      googleVerify: '谷歌验证码',
      phoneAreaCode: '手机区号'
    },
    // 账单
    bill: {
      bill: '账单',
      coin: '币种',
      to: '至',
      startDate: '开始日期',
      endDate: '结束日期',
      search: '查询',
      reset: '重置',
      orderTime: '时间',
      type: '类型',
      instructions: '说明',
      paymentType: '收支类型',
      number: '数量',
      status: '状态',
      operation: '操作',
      detail: '详情',
      noData: '暂无数据',
      every: '每页',
      recordsTotal: '条，总共',
      page: '页',
      copySuccess: '复制成功',
      copyFail: '复制失败',
      income: '收入',
      spending: '支出',
      // 币币交易详情-市价
      symbol: '交易对',
      entrustDirection: '委托方向',
      entrustType: '委托类型',
      buyMoney: '买入金额',
      buyNumber: '买入数量',
      sellNumber: '卖出数量',
      sellMoney: '卖出金额',
      tradePrice: '成交均价',
      rate: '手续费',
      entrustTime: '委托时间',
      orderNo: '订单号',
      // 币币交易详情-限价
      entrustPrice: '委托价格',
      entrustNumber: '委托量',
      tradeTime: '成交时间',
      // 币币交易详情-撤销
      cancelTime: '撤销时间',
      // 转账详情
      transferType: '转账类型',
      transferTime: '转账时间',
      Txid: 'Txid',
      sendAddress: '发送地址',
      acceptAddress: '接受地址',
      remark: '备注',
      // 收款详情
      paymentDetailType: '收款类型',
      paymentTime: '收款时间',
      // U本位合约
      openType: '开仓类型',
      openDirection: '开仓方向',
      leverage: '杠杆倍数',
      principal: '本金',
      dealPrice: '成交均价',
      dealNum: '成交数量',
      dealRate: '成交手续费',
      orderTimes: '下单时间',
      unwindType: '平仓类型',
      unwindPrice: '平仓价格',
      unwindNum: '平仓数量',
      unwindProfitLoss: '平仓盈亏',
      unwindProfitLossPer: '平仓盈亏比',
      closeFee: '成交手续费',
      returnBalance: '余额归还',
      returnBalanceTxt1: '平仓后，持仓本金和平仓盈亏会归还到余额',
      returnBalanceTxt2: '余额归还=持仓本金+平仓盈亏',
      positionDetails: '查看仓位详情',
      unwindDetails: '查看平仓详情'
    }
  },
  // 行情
  market: {
    volume: '24H 成交量',
    hq: '行情',
    keyword: '搜索',
    zx: '自选',
    bbsc: '币币市场',
    symbol: '交易对',
    close: '最新价',
    chg: '24小时涨跌幅',
    high: '24小时最高',
    low: '24小时最低',
    operate: '操作',
    detail: '详情',
    sq: '收起',
    officeSiteUrl: '官网',
    whitePagerUrl: '白皮书',
    blockExplorerUrl: '区块查询',
    coinName: '币种名称',
    publishTime: '发行时间',
    totalSupply: '发行总量',
    circulationSupply: '流通总量',
    price: '众筹价格',
    optionalSuccess: '添加自选成功',
    optionalCancel: '取消自选成功',
    noData: '暂无数据'
  },
  // 币币交易
  coinCurrencyTrading: {
    zx: '自选', 
    searchCoin: '搜索',
    symbol: "交易对",
    lastprice: '最新价',
    daychange: '涨幅',
    noData: '暂无数据',
    high: '24H高',
    low: '24H低',
    vol: '24H量',
    realtime: '分时',
    M1: '1分钟',
    M5: '5分钟',
    M15: '15分钟',
    M30: '30分钟',
    H1: '1小时',
    h4: '4小时',
    D1: '1天',
    W1: '1周',
    Month1: '1月',
    kline: "K线图",
    depth: "深度图",
    realTimeTrade: '实时成交',
    time: '时间',
    price: '价格',
    amount: '数量',
    pankou: '盘口',
    coinTrade: '币币交易',
    optionalSuccess: '添加自选成功',
    optionalCancel: '取消自选成功',
    turnOver: '成交额',
    more: '更多',
    buyPleaseEnter: '以市场上最优价格买入',
    sellPleaseEnter: '以市场上最优价格卖出',
    entrustNow: '当前委托',
    entrustHistory: '历史委托',
    orderTime: '下单时间',
    direction: '方向',
    entrustPrice: '委托价格',
    entrustNumber: '委托数量',
    delegateTotal: '委托总额',
    operation: '操作',
    tradePrice: '成交均价',
    rate: '手续费',
    status: '状态',
    completionTime: '完成时间',
    undo: '撤销',
    pageTotal: '共',
    page: '页',
    available: '可用:',
    marketPrice: '市价',
    limitPrice: '限价',
    buyPrice: '买入价',
    buyAmount: '买入数量',
    buyMoney: '买入金额',
    buy: '买入',
    sellPrice: '卖出价',
    sellAmount: '卖出数量',
    sellMoney: '卖出金额',
    sell: '卖出',
    buySuccess: '买入成功',
    sellSuccess: '卖出成功',
    buyPriceEmpty: '请输入买入价',
    buyAmountEmpty: '请输入买入数量',
    buyTotalEmpty: '请输入买入金额',
    sellPriceEmpty: '请输入卖出价',
    sellAmountEmpty: '请输入卖出数量',
    sellTotalEmpty: '请输入卖出金额',
    balanceEmpty: '可用余额不足',
    undoSuccess: '撤销成功',
    hideUndo: '隐藏已撤销',
    hideSymbol: '隐藏其他交易对',
    login: '登录',
    or: '或',
    register: '注册',
    prompt: '提示',
    confirmPrompt: '确定撤销此单',
    sure: '确认',
    out: '取消',
  },
  // U本位合约
  UStandardContract: {
    zx: '自选', 
    uStandardContract: 'U本位合约',
    searchCoin: '搜索',
    symbol:"交易对",
    lastprice: '最新价',
    daychange: '涨幅',
    noData: '暂无数据',
    high: '24H高',
    low: '24H低',
    vol: '24H量',
    leverage: '杠杆',
    leverageError: '当前有进行中的仓位或委托单，不能修改杠倍数',
    capitalRate: '本期资金费率',
    countdown: '倒计时',
    realtime: '分时',
    M1: '1分钟',
    M5: '5分钟',
    M15: '15分钟',
    M30: '30分钟',
    H1: '1小时',
    h4: '4小时',
    D1: '1天',
    W1: '1周',
    Month1: '1月',
    kline: "K线图",
    depth: "深度图",
    realTimeTrade: '实时成交',
    time: '时间',
    price: '价格',
    amount: '数量',
    pankou: '盘口',
    turnOver: '成交额',
    more: '更多',
    coinTrade: 'U本位合约',
    optionalSuccess: '添加自选成功',
    optionalCancel: '取消自选成功',
    available: '可用:',
    marketPrice: '市价',
    limitPrice: '限价',
    buyPleaseEnter: '以市场上最优价格买入',
    sellPleaseEnter: '以市场上最优价格卖出',
    buyPrice: '买入价格',
    buyMoney: '买入金额',
    buyAmount: '预计开仓数量',
    buy: '买涨',
    sellPrice: '卖出价格',
    sellMoney: '卖出金额',
    sell: '买跌',
    buySuccess: '买入成功',
    sellSuccess: '卖出成功',
    buyPriceEmpty: '请输入买入价格',
    buyTotalEmpty: '请输入买入金额',
    sellPriceEmpty: '请输入卖出价',
    sellTotalEmpty: '请输入卖出金额',
    balanceEmpty: '可用余额不足',
    login: '登录',
    or: '或',
    register: '注册',
    pageTotal: '共',
    page: '页',
    // 委托列表
    position: '持仓',
    entrustNow: '当前委托',
    entrustHistory: '历史委托',
    unwindRecord: '平仓记录',
    contract: '合约',
    direction: '方向',
    multiple: '倍数',
    averageOpen: '开仓均价',
    priceTag: '标记价格',
    flatPrice: '强平价格',
    principal: '本金',
    positionNumber: '持仓数量',
    earnings: '收益/收益率',
    traders: '交易员',
    checkFullPrice: '止盈价',
    stopLossPrice: '止损价',
    orderTime: '下单时间',
    trigger: '触发条件',
    status: '状态',
    operation: '操作',
    leverageTxt: '逐仓·',
    undo: '撤销',
    dealPrice: '成交均价',
    dealNum: '成交数量',
    completeTime: '完成时间',
    unwindPrice: '平仓价格',
    unwindNum: '平仓数量',
    returnBalance: '余额归还',
    unwindType: '平仓类型',
    unwindTime: '平仓时间',
    additional: '追加本金',
    unwind: '平仓',
    lookDetail: '查看详情',
    hideUndo: '隐藏已撤销',
    hideSymbol: '隐藏其他合约',
    allFlat: '一键全平',
    allUndo: '一键撤销',
    // 追加本金弹窗
    topUp: '充值',
    all: '全部',
    addMoney: '追加金额',
    addMoneyEnter: '请输入追加金额',
    availables: '可用',
    currentPrincipal: '当前本金',
    addPrincipal: '追加后本金',
    currentStrongParity: '当前强平价',
    addStrongParity: '追加后强平价',
    addMoneySuccess: '追加成功',
    // 撤销、平仓弹窗
    prompt: '操作提示',
    isUnwindAll: '是否确认全部平仓？',
    isUnwind: '是否确认平仓此订单？',
    isUndoAll: '是否确认全部撤销？',
    isUndo: '是否确认撤销此委托单？',
    sure: '确认',
    out: '取消',
    unwindSuccess: '平仓成功',
    undoSuccess: '撤销成功',
    // 止盈止损弹窗
    checkFullStop: '设置止盈止损',
    expectedLosses: '预计亏损',
    expectedProfits: '预计盈利',
    remove: '清除',
    checkFullPriceEnter: '请输入止盈价',
    stopLossPriceEnter: '请输入止损价',
    currentPrice: '当前价',
    openPositionPrice: '开仓价',
    strongParity: '强平价',
    adjustLever: '调整杠杆',
    setSuccess: '设置成功',
    profitPriceTxt1: '买涨时，止盈价需大于开仓价',
    profitPriceTxt2: '买涨时，止损价需大于强平价',
    profitPriceTxt3: '买涨时，止损价需小于当前价',
    lossPriceTxt1: '买跌时，止盈价需小于当前价',
    lossPriceTxt2: '买跌时，止盈价需小于开仓价',
    lossPriceTxt3: '买跌时，止损价需小于强平价',
    lossPriceTxt4: '买跌时，止损价需大于当前价'
  },
  // 币本位合约
  BStandardContract: {
    zx: '自选', 
    uStandardContract: '币本位合约',
    searchCoin: '搜索',
    symbol:"交易对",
    lastprice: '最新价',
    daychange: '涨幅',
    noData: '暂无数据',
    high: '24H高',
    low: '24H低',
    vol: '24H量',
    leverage: '杠杆',
    capitalRate: '本期资金费率',
    countdown: '倒计时',
    realtime: '分时',
    M1: '1分钟',
    M5: '5分钟',
    M15: '15分钟',
    M30: '30分钟',
    H1: '1小时',
    h4: '4小时',
    D1: '1天',
    W1: '1周',
    Month1: '1月',
    kline: "K线图",
    depth: "深度图",
    realTimeTrade: '实时成交',
    time: '时间',
    price: '价格',
    amount: '数量',
    pankou: '盘口',
    turnOver: '成交额',
    more: '更多',
    coinTrade: '币本位合约',
    optionalSuccess: '添加自选成功',
    optionalCancel: '取消自选成功',
    available: '可用:',
    marketPrice: '市价',
    limitPrice: '限价',
    buyPleaseEnter: '以市场上最优价格买入',
    sellPleaseEnter: '以市场上最优价格卖出',
    buyPrice: '买入价格',
    buyMoney: '买入金额',
    buyAmount: '预计开仓数量',
    buy: '买涨',
    sellPrice: '卖出价格',
    sellMoney: '卖出金额',
    sell: '买跌',
    buySuccess: '买入成功',
    sellSuccess: '卖出成功',
    buyPriceEmpty: '请输入买入价格',
    buyTotalEmpty: '请输入买入金额',
    sellPriceEmpty: '请输入卖出价',
    sellTotalEmpty: '请输入卖出金额',
    balanceEmpty: '可用余额不足',
    login: '登录',
    or: '或',
    register: '注册',
    pageTotal: '共',
    page: '页',
    // 委托列表
    position: '持仓',
    entrustNow: '当前委托',
    entrustHistory: '历史委托',
    unwindRecord: '平仓记录',
    contract: '合约',
    direction: '方向',
    multiple: '倍数',
    averageOpen: '开仓均价',
    priceTag: '标记价格',
    flatPrice: '强平价格',
    principal: '本金',
    positionNumber: '仓位价值',
    earnings: '收益/收益率',
    checkFullPrice: '止盈价',
    stopLossPrice: '止损价',
    orderTime: '下单时间',
    trigger: '触发条件',
    status: '状态',
    operation: '操作',
    leverageTxt: '逐仓·',
    undo: '撤销',
    dealPrice: '成交均价',
    dealNum: '成交数量',
    completeTime: '完成时间',
    unwindPrice: '平仓价格',
    unwindNum: '平仓数量',
    returnBalance: '余额归还',
    unwindType: '平仓类型',
    unwindTime: '平仓时间',
    additional: '追加本金',
    unwind: '平仓',
    lookDetail: '查看详情',
    hideUndo: '隐藏已撤销',
    hideSymbol: '隐藏其他合约',
    allFlat: '一键全平',
    allUndo: '一键撤销',
    // 撤销、平仓弹窗
    prompt: '操作提示',
    isUnwindAll: '是否确认全部平仓？',
    isUnwind: '是否确认平仓此订单？',
    isUndoAll: '是否确认全部撤销？',
    isUndo: '是否确认撤销此委托单？',
    sure: '确认',
    out: '取消',
    unwindSuccess: '平仓成功',
    undoSuccess: '撤销成功',
    adjustLever: '调整杠杆'
  },
  // 币币交易-买卖盘
  tradeDish: {
    coincurrencytrading: '现货交易',
    ustandardcontract: 'U本位合约',
    currencystandardcontract: '币本位合约',
    tradeDish: '买卖盘',
    buyDisc: '买盘',
    buyPrice: '买入价',
    buyAmount: '买入量',
    buyCumulative: '累计',
    buy: '买',
    sellDisc: '卖盘',
    sell: '卖',
    sellPrice: '卖入价',
    sellAmount: '卖入量',
    sellCumulative: '累计',
  },
  components: {
    positionDetails: {
      positionDetails: '持仓详情',
      closingDetails: '平仓详情',
      operationTip: '操作提示',
      closePositionThisOrder: '是否确认平仓此订单？',
      many: '多',
      less: '少',
      bear: '空',
      profit: '收益',
      yeld: '收益率',
      averageOpeningPrice: '开仓均价',
      principal: '本金',
      positionNum: '持仓数量',
      strongParity: '强平价',
      stopLossPrice: '止损价',
      stopProfitPrice: '止盈价',
      closingPrice: '平仓价',
      closingType: '平仓类型',
      userClosing: '用户平仓',
      afterClosingTip: '平仓后，持仓本金和平仓盈亏会归还到余额',
      balanceReturnTip: '余额归还=持仓本金+平仓盈亏',
      balanceReturn: '余额归还',
      additionalPrincipal: '追加本金',
      stopProfitStopLoss: '止盈止损',
      closePosition: '平仓',
      operationRecord: '操作记录',
      capitalCost: '资金费用',
      clearStopProfitPrice: '清除止盈价',
      setStopLossPrice: '设置止损价',
      openPosition: '开仓',
      price: '价格',
      number: '数量',
      amountOfMoney: '金额',
      serviceCharge: '手续费',
      strongLiquidationPrice: '强平清算价'
    }
  }
}

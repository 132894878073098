<template>
  <svg :width="width" :height="height" viewBox="0 0 52 35" fill="currentcolor" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2529_12180)">
      <path d="M45.6482 0H6.3518C4.66789 0.00187593 3.05348 0.665868 1.86272 1.84632C0.671954 3.02677 0.00206445 4.6273 0 6.29679L0 28.7026C0.00206445 30.3721 0.671954 31.9726 1.86272 33.153C3.05348 34.3335 4.66789 34.9975 6.3518 34.9994H45.6482C47.3321 34.9975 48.9465 34.3335 50.1373 33.153C51.328 31.9726 51.9979 30.3721 52 28.7026V6.29744C51.9983 4.62778 51.3285 3.02699 50.1377 1.84636C48.9469 0.665732 47.3323 0.001706 45.6482 0ZM42.5445 4.10828L26 14.6758L9.45555 4.10699L42.5445 4.10828ZM45.6482 30.8917H6.3518C5.76567 30.8917 5.20355 30.6609 4.78909 30.25C4.37464 29.8391 4.1418 29.2817 4.1418 28.7006V6.41537L24.7214 19.5335C24.7494 19.5503 24.7786 19.5619 24.8072 19.578C24.8358 19.5941 24.867 19.6102 24.8976 19.625C25.0541 19.706 25.2191 19.7698 25.3896 19.8151C25.4065 19.8203 25.4241 19.8222 25.441 19.8261C25.6235 19.8708 25.8107 19.8944 25.9987 19.8963H26C26.188 19.8947 26.3752 19.8711 26.5577 19.8261C26.5746 19.8222 26.5921 19.8203 26.609 19.8151C26.7809 19.7705 26.9472 19.7071 27.105 19.6263C27.1355 19.6115 27.1654 19.5954 27.1953 19.5793C27.2252 19.5632 27.2532 19.5516 27.2811 19.5348L47.8575 6.41537V28.7026C47.857 29.2832 47.6241 29.8399 47.2098 30.2504C46.7955 30.6609 46.2339 30.8915 45.6482 30.8917Z"/>
    </g>
    <defs>
      <clipPath id="clip0_2529_12180">
        <rect width="52" height="35"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
  export default {
    props: {
      width: {
        default: '36'
      },
      height: {
        default: '26'
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>
import Vue from "vue";
import Vuex from "vuex";

import { pageData } from "./module/pageData";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLogin: true, // 是否登录
    internationalCurrency: "CNY", // 国际币种
    internationalCurrencyRate: "", // 国际币种费率
    internationalCurrencySymbol: "￥", // 国际币种符号
    currentPage: 1, // 分页默认显示第一页
    pageSize: 10, // 分页默认展示每页的个数
    exportExcelMax: 30000, // 导出excel的最大条数位30000
    nowLongDate: "", // 格式化年月日时分秒 20180530080808
    languageType: "en",
    languageContent: "English",
    languageFace:
      "https://exch8899.oss-cn-hongkong.aliyuncs.com/language/1/en.png",
    langObj: {},
  },
  getters: {
    langObjData(state) {
      return state.langObj;
    },
  },
  mutations: {
    // 时间戳
    timeStamp(state) {
      let now = new Date();
      let year = now.getFullYear(); // getFullYear getYear
      let month = now.getMonth();
      let date = now.getDate();
      let hour = now.getHours();
      let minu = now.getMinutes();
      let sec = now.getSeconds();
      month = month + 1;
      if (month < 10) {
        month = "0" + month;
      }
      if (date < 10) {
        date = "0" + date;
      }
      if (hour < 10) {
        hour = "0" + hour;
      }
      if (minu < 10) {
        minu = "0" + minu;
      }
      if (sec < 10) {
        sec = "0" + sec;
      }
      state.nowLongDate =
        year + "" + month + "" + date + "" + hour + "" + minu + "" + sec + "";
      // console.log('vuex=nowLongDate=', state.nowLongDate)
    },
    // 是否登录
    isLogin(state, isLogin) {
      state.isLogin = isLogin;
    },
    // 国际币种
    internationalCurrency(state, internationalCurrency) {
      state.internationalCurrency = internationalCurrency;
    },
    // 国际币种费率
    internationalCurrencyRate(state, internationalCurrencyRate) {
      state.internationalCurrencyRate = internationalCurrencyRate;
    },
    // 国际币种符号
    internationalCurrencySymbol(state, internationalCurrencySymbol) {
      state.internationalCurrencySymbol = internationalCurrencySymbol;
    },
    // 语言key
    changeLanguageType(state, languageType) {
      state.languageType = languageType;
    },
    // 语言value
    changeLanguageContent(state, languageContent) {
      state.languageContent = languageContent;
    },
    // 语言img
    changeLanguageFace(state, languageFace) {
      state.languageFace = languageFace;
    },
    // 所有的语言
    changeLangObj(state, langObj) {
      state.langObj = langObj;
    },
  },
  actions: {
    // 时间戳
    getNowLongDate({ commit }) {
      commit("timeStamp");
    },
  },
  modules: {
    pageData,
  },
});

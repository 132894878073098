let baseUrl = process.env.VUE_APP_BASIC_API
let baseUrl1 = process.env.VUE_APP_BASIC_API1
let baseUrl2 = process.env.VUE_APP_BASIC_API2

const serviceModule = {
  // 登录
  login: {
    baseURL: baseUrl,
    url: '/login',
    method: 'post'
  },
  // 登录的控制器 - 登录安全验证
  safetyVerification: {
    baseURL: baseUrl,
    url: '/safety/verification',
    method: 'post'
  },
  // 验证码管理 - 用户登录获取邮箱验证码
  emailLogin: {
    baseURL: baseUrl,
    url: '/verify/email/login',
    method: 'post'
  },
  // 验证码管理 - 用户登录获取短信码
  smsLogin: {
    baseURL: baseUrl,
    url: '/verify/sms/login',
    method: 'post'
  },
  // 登出
  logout: {
    baseURL: baseUrl,
    url: '/logout',
    method: 'post'
  },
  // 用户管理模块 - 用户注册
  memberRegister: {
    baseURL: baseUrl,
    url: '/member/register',
    method: 'post'
  },
  // 验证码管理 - 用户注册获取邮箱验证码
  verifyEmailRegistered: {
    baseURL: baseUrl,
    url: '/verify/email/registered',
    method: 'post'
  },
  // 验证码管理 - 用户注册获取短信码
  verifySmsRegistered: {
    baseURL: baseUrl,
    url: '/verify/sms/registered',
    method: 'post'
  },
  // 用户管理模块 - 忘记密码安全验证信息
  memberForgetInfo: {
    baseURL: baseUrl,
    url: '/member/forget/info',
    method: 'get'
  },
  // 验证码管理 - 忘记密码二次安全验证
  verifyForgetPwdVerification: {
    baseURL: baseUrl,
    url: '/verify/forget/pwd/verification',
    method: 'post'
  },
  // 验证码管理 - 忘记密码获取邮箱验证码
  verifyEmailForget: {
    baseURL: baseUrl,
    url: '/verify/email/forget',
    method: 'post'
  },
  // 验证码管理 - 忘记密码获取短信码
  verifySmsForget: {
    baseURL: baseUrl,
    url: '/verify/sms/forget',
    method: 'post'
  },
  // 用户管理模块 - 忘记密码重设密码
  memberForgetResetPwd: {
    baseURL: baseUrl,
    url: '/member/forget/reset/pwd',
    method: 'post'
  },
  // 用户管理模块 - 用户信息
  memberInfo: {
    baseURL: baseUrl,
    url: '/member/info',
    method: 'get'
  },
  // 用户管理模块 - 修改登录密码
  memberModifyLoginPassword: {
    baseURL: baseUrl,
    url: '/member/modifyLoginPassword',
    method: 'post'
  },
  // 用户管理模块 - 绑定手机号
  memberBidingPhone: {
    baseURL: baseUrl,
    url: '/member/biding/phone',
    method: 'post'
  },
  // 验证码管理 - 安全验证获取短信验证码
  verifySmsSafety: {
    baseURL: baseUrl,
    url: '/verify/sms/safety',
    method: 'post'
  },
  // 用户管理模块 - 修改手机
  memberModifyLoginMobile: {
    baseURL: baseUrl,
    url: '/member/modifyLoginMobile',
    method: 'post'
  },
  // 用户管理模块 - 绑定邮箱
  memberBidingEmail: {
    baseURL: baseUrl,
    url: '/member/biding/email',
    method: 'post'
  },
  // 验证码管理 - 安全验证获取邮箱验证码
  verifyEmailSafety: {
    baseURL: baseUrl,
    url: '/verify/email/safety',
    method: 'post'
  },
  // 用户管理模块 - 开启Google验证获取验证信息
  memberGetGoogleSecret: {
    baseURL: baseUrl,
    url: '/member/getGoogleSecret',
    method: 'post'
  },
  // 用户管理模块 - 开启Google验证
  memberOpenGoogleSecret: {
    baseURL: baseUrl,
    url: '/member/openGoogleSecret',
    method: 'post'
  },
  // 用户管理模块 - 关闭Google验证
  memberCloseGoogleVerify: {
    baseURL: baseUrl,
    url: '/member/closeGoogleVerify',
    method: 'post'
  },
  // 用户管理模块 - 修改Google验证
  memberModifyGoogleVerify: {
    baseURL: baseUrl,
    url: '/member/modifyGoogleVerify',
    method: 'post'
  },
  // app用户认证 - 设置支付密码
  setPayPassword: {
    baseURL: baseUrl,
    url: '/approve/set/payPassword',
    method: 'post'
  },
  // app用户认证 - 重置支付密码
  resetPayPassword: {
    baseURL: baseUrl,
    url: '/approve/reset/payPassword',
    method: 'post'
  },
  // 用户管理模块 - 登录日志
  memberLgLog: {
    baseURL: baseUrl,
    url: '/member/lgLog',
    method: 'get'
  },
  // 账单 - 类型下拉框
  typeArr: {
    baseURL: baseUrl,
    url: '/bill/type/list',
    method: 'get'
  },
  // 账单 - 币种下拉框
  coinArr: {
    baseURL: baseUrl,
    url: '/bill/coinInfo',
    method: 'get'
  },
  // 账单 - 状态下拉框
  statusArr: {
    baseURL: baseUrl,
    url: '/bill/status/list',
    method: 'get'
  },
  // 账单 - 账单列表
  billList: {
    baseURL: baseUrl,
    url: '/bill/list',
    method: 'post'
  },
  // 账单 - 币币交易详情
  tradeDetail: {
    baseURL: baseUrl1,
    url: '/trade/order/detail',
    method: 'get'
  },
  // 账单 - 转账/收款详情
  billDetail: {
    baseURL: baseUrl,
    url: '/bill/info',
    method: 'get'
  },
  // 账单 - 合约详情
  contractDetail: {
    baseURL: baseUrl,
    url: '/bill/contract/info',
    method: 'get'
  },
  // 账单 - 超级杠杆详情
  superLeverDetail: {
    baseURL: baseUrl,
    url: '/bill/margin/info',
    method: 'get'
  },
  // 账单 - 账单详情(用户赠送)
  rewardDetail: {
    baseURL: baseUrl,
    url: '/bill/reward/info',
    method: 'get'
  },
  // 行情-查询汇率列表
  marketCurrencyRate: {
    baseURL: baseUrl1,
    url: '/market/currency/rate',
    method: 'get'
  },
  // 行情-查询指定币种汇率
  marketCurrencyRateOne: {
    baseURL: baseUrl1,
    url: '/market/exchange-rate/usd-',
    method: 'get'
  },
  // 行情-查询四个交易对
  symbolThumbTrend: {
    baseURL: baseUrl1,
    url: '/market/index/symbol-thumb-trend',
    method: 'get'
  },
  // 行情-查询行情
  symbolThumbCoin: {
    baseURL: baseUrl1,
    url: '/market/coin/symbol-thumb',
    method: 'get'
  },
  // 行情-查询自选
  optionalSymbolThumb: {
    baseURL: baseUrl1,
    url: '/user/optional/symbol-thumb',
    method: 'get'
  },
  // 行情-添加自选
  userOptionalAdd: {
    baseURL: baseUrl1,
    url: '/user/optional/add',
    method: 'post'
  },
  // 行情-删除自选
  userOptionalDel: {
    baseURL: baseUrl1,
    url: '/user/optional/del',
    method: 'post'
  },
  // 币币交易-实时成交
  coinTrade: {
    baseURL: baseUrl1,
    url: '/market/latest-trade',
    method: 'get'
  },
  // 币币交易-查询余额
  queryBalance: {
    baseURL: baseUrl,
    url: '/wallet/digit/coin/single/balance',
    method: 'post'
  },
  // 币币交易-买入市价
  buyMarketPrice: {
    baseURL: baseUrl1,
    url: '/trade/market/order/buy',
    method: 'post'
  },
  // 币币交易-买入限价
  buyLimitPrice: {
    baseURL: baseUrl1,
    url: '/trade/limit/order/buy',
    method: 'post'
  },
  // 币币交易-卖出市价
  sellMarketPrice: {
    baseURL: baseUrl1,
    url: '/trade/market/order/sell',
    method: 'post'
  },
  // 币币交易-卖出限价
  sellLimitPrice: {
    baseURL: baseUrl1,
    url: '/trade/limit/order/sell',
    method: 'post'
  },
  // 币币交易-当前委托列表
  entrustNow: {
    baseURL: baseUrl1,
    url: '/trade/order/open/view',
    method: 'get'
  },
  // 币币交易-历史委托列表
  entrustNowHistory: {
    baseURL: baseUrl1,
    url: '/trade/order/history/view',
    method: 'get'
  },
  // 币币交易-撤销委托
  undoEntrust: {
    baseURL: baseUrl1,
    url: '/trade/order/cancel',
    method: 'post'
  },
  // k线深度图
  marketPlateFull: {
    baseURL: baseUrl1,
    url: '/market/exchange-plate',
    method: 'get'
  },
  // U本位合约-查询U本位合约列表
  symbolThumbUContract: {
    baseURL: baseUrl1,
    url: '/market/contract/usdt/symbol-thumb',
    method: 'get'
  },
  // U本位合约-买涨买跌
  queryContractAmount: {
    baseURL: baseUrl1,
    url: '/contract/expected/open/positions',
    method: 'get'
  },
  // U本位合约-买涨买跌
  buyMarketPriceU: {
    baseURL: baseUrl1,
    url: '/contract/order/open',
    method: 'post'
  },
  // U本位合约-委托列表
  entrustU: {
    baseURL: baseUrl1,
    url: '/contract/order/page',
    method: 'get'
  },
  // U本位合约-止盈止损
  profitAndLoss: {
    baseURL: baseUrl1,
    url: '/contract/set/profitAndLoss',
    method: 'post'
  },
  // U本位合约-查询预计盈利、预计亏损
  profitAndLossPrice: {
    baseURL: baseUrl1,
    url: '/contract/price/loss/profit',
    method: 'get'
  },
  // U本位合约-查询⼿续费率
  queryFreeRate: {
    baseURL: baseUrl1,
    url: '/contract/freeRate',
    method: 'get'
  },
   // U本位合约-查询强平价
   additional: {
    baseURL: baseUrl1,
    url: '/contract/additional/principal',
    method: 'post'
  },
  // U本位合约-追加本金
  queryLiquidate: {
    baseURL: baseUrl1,
    url: '/contract/forced/after/liquidate',
    method: 'get'
  },
  // U本位合约-平仓
  unwind: {
    baseURL: baseUrl1,
    url: '/contract/order/SellAll',
    method: 'post'
  },
  // U本位合约-一键全平
  unwindAll: {
    baseURL: baseUrl1,
    url: '/contract/order/clickSellAll/1',
    method: 'post'
  },
  // U本位合约-撤销
  contractUndo: {
    baseURL: baseUrl1,
    url: '/contract/cancel/order',
    method: 'post'
  },
  // U本位合约-一键撤销
  contractUndoAll: {
    baseURL: baseUrl1,
    url: '/contract/one-click/undo',
    method: 'post'
  },
  // 币本位合约-查询币本位合约列表
  symbolThumbBContract: {
    baseURL: baseUrl1,
    url: '/market/contract/coin/symbol-thumb',
    method: 'get'
  },
  // 币本位合约-一键全平
  unwindAllB: {
    baseURL: baseUrl1,
    url: '/contract/order/clickSellAll/2',
    method: 'post'
  },
  // 资产-钱包 - 资产余额-数字货币pc
  walletDigitCoin: {
    baseURL: baseUrl,
    url: '/wallet/digit/coin/pc',
    method: 'post'
  },
  // 资产-钱包 - 资产余额-法币
  walletLegalTender: {
    baseURL: baseUrl,
    url: '/wallet/legal/tender',
    method: 'post'
  },
  // 资产-钱包 - 资产总览
  walletPandect: {
    baseURL: baseUrl,
    url: '/wallet/pandect',
    method: 'post'
  },
  // 资产 - 链信息
  assetsChain: {
    baseURL: baseUrl,
    url: '/assets/chain',
    method: 'get'
  },
  // 资产 - 币种信息
  assetsCoinList: {
    baseURL: baseUrl,
    url: '/assets/coin/list',
    method: 'post'
  },
  // 资产 - 新增转账地址薄
  assetsTransferAddressAdd: {
    baseURL: baseUrl,
    url: '/assets/transfer/address/add',
    method: 'post'
  },
  // 资产 - 转账地址薄
  assetsTransferAddressBook: {
    baseURL: baseUrl,
    url: '/assets/transfer/address/book',
    method: 'post'
  },
  // 资产 - 删除转账地址薄
  assetsTransferAddressDel: {
    baseURL: baseUrl,
    url: '/assets/transfer/address/del',
    method: 'post'
  },
  // 资产 - 收款地址
  assetsPaymentAddress: {
    baseURL: baseUrl,
    url: '/assets/payment/address',
    method: 'post'
  },
  // 资产 - 新增收款地址
  assetsPaymentAddressAdd: {
    baseURL: baseUrl,
    url: '/assets/payment/address/add',
    method: 'post'
  },
  // 资产 - 修改收款地址备注
  assetsPaymentAddressUpdate: {
    baseURL: baseUrl,
    url: '/assets/payment/address/update',
    method: 'post'
  },
  // 资产 - 删除收款地址备注
  assetsPaymentAddressDel: {
    baseURL: baseUrl,
    url: '/assets/payment/address/del',
    method: 'post'
  },
  // 资产 - 提币
  assetsWithdraw: {
    baseURL: baseUrl,
    url: '/assets/withdraw',
    method: 'post'
  },
  // 资产 - 提币
  assetsWithdrawAmount: {
    baseURL: baseUrl,
    url: '/assets/withdraw/24h/amount',
    method: 'get'
  },
  // 资产-账单 - 充提币记录
  billDepositWithdrawalRecords: {
    baseURL: baseUrl,
    url: '/bill/deposit—withdrawal/records',
    method: 'post'
  },
  // 行情模块 - 查询所有交易对
  marketAllPair: {
    baseURL: baseUrl1,
    url: '/market/all/pair',
    method: 'get'
  },
  // 用户管理模块 - 会员修改个人信息
  memberModifyInfo: {
    baseURL: baseUrl,
    url: '/member/modifyInfo',
    method: 'get'
  },
  // oss服务 - policy
  ossPolicy: {
    baseURL: baseUrl,
    url: '/oss/policy',
    method: 'get'
  },
  // 资产-钱包 - 合约持仓额度(区分u本位和币本位)
  walletBalance: {
    baseURL: baseUrl,
    url: '/wallet/contract/distinguish/balance',
    method: 'get'
  },
  // 合约模块 - 合约订单详情查询
  orderDetail: {
    baseURL: baseUrl1,
    url: '/contract/order/detail',
    method: 'get'
  },
  // 邀请码管理 - 新增邀请码
  inviteAdd: {
    baseURL: baseUrl,
    url: '/invite/manage/add',
    method: 'post'
  },
  // 邀请码管理 - 删除邀请码
  inviteEdit: {
    baseURL: baseUrl,
    url: '/invite/manage/edit',
    method: 'post'
  },
  // 邀请码管理 - 编辑邀请码
  inviteDel: {
    baseURL: baseUrl,
    url: '/invite/manage/del',
    method: 'post'
  },
  // 邀请码管理 - 我的累计返利
  inviteRebate: {
    baseURL: baseUrl,
    url: '/invite/manage/cumulative/rebate',
    method: 'post'
  },
  // 邀请码管理 - 好友列表
  friendsList: {
    baseURL: baseUrl,
    url: '/invite/manage/friends/list',
    method: 'post'
  },
  // 邀请码管理 - 返利配置
  inviteConfig: {
    baseURL: baseUrl,
    url: '/invite/manage/option/config',
    method: 'get'
  },
  // 邀请码管理 - 数据总览
  inviteOverview: {
    baseURL: baseUrl,
    url: '/invite/manage/overview',
    method: 'post'
  },
  // 邀请码管理 - 分享海报
  invitePosters: {
    baseURL: baseUrl,
    url: '/invite/manage/posters',
    method: 'get'
  },
  // 邀请码管理 - 我的邀请码
  inviteQueryList: {
    baseURL: baseUrl,
    url: '/invite/manage/query/list',
    method: 'post'
  },
  // 邀请码管理 - 返利类型
  rebateType: {
    baseURL: baseUrl,
    url: '/invite/manage/rebateType',
    method: 'post'
  },
  // 邀请码管理 - 我的邀请统计
  inviteStatistics: {
    baseURL: baseUrl,
    url: '/invite/manage/statistics',
    method: 'get'
  },
  // 资产-账单 - 账单详情(分发)
  billCommissionInfo: {
    baseURL: baseUrl,
    url: '/bill/commission/info',
    method: 'get'
  },
  // 聊天-用户注册
  userRegister: {
    baseURL: baseUrl2,
    url: '/hx/getUser',
    method: 'get'
  },
  // 聊天-获取群聊信息
  getGroupChat: {
    baseURL: baseUrl2,
    url: '/hx/getGroupChat',
    method: 'post'
  },
  // 广告管理 - banner广告
  adBanner: {
    baseURL: baseUrl,
    url: '/ad/banner',
    method: 'get'
  },
  // 邀请码管理 - 数据总览统计
  overviewStatistics: {
    baseURL: baseUrl,
    url: '/invite/manage/overview/statistics',
    method: 'post'
  },
  // 头部右侧-获取多语言
  languageType: {
    baseURL: baseUrl,
    url: '/language/i18n/type',
    method: 'get'
  },
  // 头部右侧-获取多语言
  getByLang: {
    baseURL: baseUrl,
    url: '/language/getByLang',
    method: 'get'
  },
  // 身份认证 - 国家地区选项
  countryOptions: {
    baseURL: baseUrl,
    url: '/identity/country/options',
    method: 'post'
  },
  // 邮箱 - 订阅
  emailSubscribe: {
    baseURL: baseUrl,
    url: '/email/subscribe/',
    method: 'post'
  }
}
const ApiSetting = { ...serviceModule }

export default ApiSetting

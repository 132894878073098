import Vue from 'vue'
import router from '@/router'
import axios from 'axios'

axios.interceptors.request.use(config => {
  return config
}, error => {
  return Promise.reject(error)
})

axios.interceptors.response.use(response => {
  return response
}, error => {
  return Promise.resolve(error.response)
})

function errorState (res) {
  console.log(res)
  // 如果http状态码正常，则直接返回数据
  if (res && (res.status === 200 || res.status === 304 || res.status === 400)) {
    return res
    // 如果不需要除了data之外的数据，可以直接 return res.data
  } else {
    Vue.prototype.$message({
      message: '服务器异常,请稍等...',
      type: 'warning'
    })
  }
}

function successState (res) {
  // console.log(res)
  // 统一判断后端返回的错误码
  if (res.data.statusCode !== '000000' || res.status !== 200) {
    Vue.prototype.$message({
      message: res.data.errorMessage,
      type: 'warning'
    })
    // 登录超时
    if (res.data.statusCode === '110000') {
      sessionStorage.removeItem('sessionID')
      router.push({ name: 'login' })
    }
  }
  if (res.status === 404 || res.status === 403) {
    Vue.prototype.$message({
      message: '服务器异常,请稍等...',
      type: 'error'
    })
  }
}

const httpServer = (opts = {}, data = {}, headers = {}) => {
  // 公共参数
  let Public = {
    // '': ''
  }

  let getHeaders = {
    'X-Requested-With': 'XMLHttpRequest',
    'Accept': 'application/json',
    'Content-Type': 'application/json; charset=UTF-8',
    'source': 'pc'
  }

  let postHeaders = {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json; charset=UTF-8',
    'source': 'pc'
  }

  // 不过滤掉空值的参数，后台不能返回数据
  for (let k in data) {
    if (data.hasOwnProperty(k)) {
      if (data[k] === undefined || data[k] === null || data[k] === '') {
        delete data[k]
      }
    }
  }
  var authorization = { 'Authorization': sessionStorage.getItem('sessionID') }
  // http默认配置
  let httpDefaultOpts = {
    method: opts.method,
    baseURL: opts.baseURL,
    url: opts.url,
    timeout: 60000,
    params: Object.assign(Public, data),
    data: Object.assign(Public, data),
    headers: Object.assign(opts.method === 'get' ? getHeaders : postHeaders, authorization, headers),
    withCredentials: true // 允许携带cookie
  }

  if (opts.method === 'get') {
    delete httpDefaultOpts.data
  } else {
    delete httpDefaultOpts.params
  }

  let promise = new Promise(function (resolve, reject) {
    axios(httpDefaultOpts).then(
      (res) => {
        successState(res)
        resolve(res)
      }
    ).catch(
      (response) => {
        errorState(response)
        reject(response)
      }
    )
  })
  return promise
}

export default httpServer

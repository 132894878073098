import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

// Router的内置属性
export default new Router({
  routes: [
    {
      path: "/",
      redirect: "/AboutUs",
    },
    {
      path: "/HomePage",
      component: () => import("./views/homePage/HomePage.vue"),
    },
    {
      path: "/Market",
      name: "coinCurrencyTrading",
      component: () => import("./views/coinCurrencyTrading/Market.vue"),
      // meta: {
      //   requireAuth: true // 添加该字段，表示进入这个路由是需要登录的
      // },
      children: [],
    },
    {
      path: "/CoinCurrencyTrading",
      component: () =>
        import("./views/coinCurrencyTrading/CoinCurrencyTrading.vue"),
    },
    {
      path: "/CoinCurrencyTrading/:pair",
      name: "CoinCurrencyTradingPair",
      component: () =>
        import("./views/coinCurrencyTrading/CoinCurrencyTrading.vue"),
    },
    {
      path: "/UStandardContract",
      component: () =>
        import("./views/coinCurrencyTrading/UStandardContract.vue"),
    },
    {
      path: "/UStandardContract/:pair",
      name: "UStandardContractPair",
      component: () =>
        import("./views/coinCurrencyTrading/UStandardContract.vue"),
    },
    {
      path: "/CurrencyStandardContract",
      component: () =>
        import("./views/coinCurrencyTrading/CurrencyStandardContract.vue"),
    },
    {
      path: "/CurrencyStandardContract/:pair",
      name: "CurrencyStandardContractPair",
      component: () =>
        import("./views/coinCurrencyTrading/CurrencyStandardContract.vue"),
    },
    {
      path: "/TradeDish",
      component: () => import("./views/coinCurrencyTrading/TradeDish.vue"),
    },
    {
      path: "/FinancialServices",
      component: () =>
        import("./views/coinCurrencyTrading/FinancialServices.vue"),
    },
    {
      name: "login",
      path: "/Login",
      component: () => import("./views/login/Login.vue"),
    },
    {
      name: "SecondaryVerification",
      path: "/SecondaryVerification",
      component: () => import("./views/login/SecondaryVerification.vue"),
    },
    {
      name: "forgetPwd",
      path: "/forgetPwd",
      component: () => import("./views/login/forgetPwd.vue"),
    },
    {
      name: "register",
      path: "/register",
      component: () => import("./views/login/registerAccount.vue"),
    },
    // 介绍页面
    {
      name: "AboutUs",
      path: "/AboutUs",
      component: () => import("./views/intro/aboutUs/AboutUs.vue"),
    },
    {
      name: "GetStarted",
      path: "/GetStarted",
      component: () => import("./views/intro/getStarted/GetStarted.vue"),
    },
    {
      name: "CryptoAcademy",
      path: "/CryptoAcademy",
      component: () => import("./views/intro/cryptoAcademy/CryptoAcademy.vue"),

    },

    {
      name: "BestCrypto",
      path: "/CryptoAcademy/bestCrypto",
      component: () =>
        import(
          "./views/intro/cryptoAcademy/cryptoAcademyComponents/bestCrypto/BestCrypto.vue"
        ),
    },
    {
      name: "ArticlesAll",
      path: "/CryptoAcademy/articlesAll",
      component: () =>
        import("./views/intro/cryptoAcademy/cryptoAcademyComponents/articlesViewAll/ArticelsViewAll.vue"),
    },

    {
      path: "/pageUCenter",
      component: () => import("./views/pageUCenter/pageUCenter.vue"),
      children: [
        {
          path: "/pageUCenter/personalOverview",
          component: () => import("./views/pageUCenter/personalOverview.vue"),
        },
        {
          path: "/pageUCenter/securitySetting",
          component: () => import("./views/pageUCenter/securitySetting.vue"),
        },
        {
          path: "/pageUCenter/AssetManagement",
          component: () => import("./views/pageUCenter/AssetManagement.vue"),
        },
        {
          path: "/pageUCenter/TransactionManagement",
          component: () =>
            import("./views/pageUCenter/TransactionManagement.vue"),
        },
        {
          path: "/pageUCenter/FinancialManagement",
          component: () =>
            import("./views/pageUCenter/FinancialManagement.vue"),
        },
        {
          path: "/pageUCenter/PromotionCenter",
          component: () => import("./views/pageUCenter/PromotionCenter.vue"),
        },
        {
          path: "/pageUCenter/CardCenter",
          component: () => import("./views/pageUCenter/CardCenter.vue"),
        },
        {
          path: "/pageUCenter/BillManagement",
          component: () => import("./views/pageUCenter/BillManagement.vue"),
        },
        {
          name: "rechargeWithdrawal",
          path: "/pageUCenter/AssetManagement/RechargeWithdrawal",
          component: () => import("./views/pageUCenter/RechargeWithdrawal.vue"),
        },
        {
          name: "rechargeWithdrawalPair",
          path: "/pageUCenter/AssetManagement/RechargeWithdrawal/:pair",
          component: () => import("./views/pageUCenter/RechargeWithdrawal.vue"),
        },
      ],
    },
  ],
});

<template>
  <div>
   <template v-if="!isShowIntro">
    <img class="go-chat" src="../assets/images/chat/go-chat.png" alt="" @click="goChat">
   </template>
   <template v-else>
    <div class="intro-imgs" :class="{'mobile-img': $device.mobile}">
      <div
        class="share"
        @click.stop="shareHandleClick"
        @mouseover="shareHandleMouseOver($event)"
        @mouseout="shareHandleMouseOut($event)">
        <img src="@/assets/images/intro/share.png" alt="">
        <div v-show="isShowShareImg" class="share-list">
          <img src="@/assets/images/intro/tw.png" alt="" @click="jumpShareClick('tw')">
          <img src="@/assets/images/intro/ins.png" alt="" @click="jumpShareClick('ins')">
          <img src="@/assets/images/intro/fb.png" alt="" @click="jumpShareClick('fb')">
          <img src="@/assets/images/intro/tel.png" alt="" @click="jumpShareClick('tel')">
          <img src="@/assets/images/intro/tt.png" alt="" @click="jumpShareClick('tt')">
          <img src="@/assets/images/intro/ytb.png" alt="" @click="jumpShareClick('ytb')">
          <img src="@/assets/images/intro/in.png" alt="" @click="jumpShareClick('in')">
        </div>
      </div>
      <div v-if="!$device.mobile" class="customer-service">
        <img src="@/assets/images/intro/customer-service.svg" alt="" @click="goChat">
      </div>
    </div>
   </template>

    <div class="current-conversation" v-show="chatFlag">
      <div class="chat-title-box">
        <span class="chat-title">{{langObj['联系客服']}}</span>
        <img class="chat-img" src="../assets/images/chat/close.png" alt="" @click="closeChatImg">
      </div>
      <div class="content" ref="content" v-loading="refreshLoading">
        <div v-for="(item, index) in message" :key="index">
          <div :class="item.from === hxUsername ? 'line-box-right' : 'line-box'" v-if="item.type==='txt'&&!item.reactions">
            <img class="head-portrait" :src="item.ext.avatarUrl" alt="">
            <div class="base-info">
              <span class="time">{{item.time | timeFilter}}</span>
              <div class="msg-box">
                <span class="msg-txt">{{item.msg}}</span>
              </div>
              <span class="official-news" v-if="item.from !== hxUsername">{{langObj['官方消息']}}</span>
            </div>
          </div>
          <div :class="item.from === hxUsername ? 'line-box-right' : 'line-box'"  v-if="item.type==='img'">
            <img class="head-portrait" :src="item.ext.avatarUrl" alt="">
            <div class="base-info">
              <span class="time">{{item.time | timeFilter}}</span>
              <div class="msg-box" @click="handlePreview(item.url)">
                <img class="msg-img" :src="item.url" alt="">
              </div>
              <span class="official-news" v-if="item.from !== hxUsername">{{langObj['官方消息']}}</span>
            </div>
          </div>
          <!-- 撤回消息 -->
          <div class="line-box" v-if="item.reactions ? item.reactions[0].reaction === 'customerWithdraw' : ''">
            <div class="txt-center">{{langObj['对方撤回了一条消息']}}</div>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="add-box">
          <img class="add" src="../assets/images/chat/add.png" alt="">
          <input
              type="file"
              id="imagePicker"
              accept="image/*"
              @change="sendImage"
              style="opacity:0"
            />
        </div>
        <div class="textarea-box">
          <textarea
            ref="text-input"
            v-model="messageContent"
            @focus="focusDiv"
            class="text-input"
            id="textarea"
            :placeholder="langObj['请输入您的信息']"
          >
          </textarea>
        </div>
        <div class="send-box">
          <span :class="!messageContent.trim()?'send1':'send'" @click="sendMsg">{{langObj['发送']}}</span>
        </div>
      </div>
      <div class="layer-box" v-if="imgFlag"  @click="imgFlag=false">
        <img class="layer-img" :src="imgSrc" alt="">
      </div>
    </div>
  </div>
</template>
<script>
import WebIM from 'easemob-websdk'
import { timeChange } from '../utils/comment'
import debounce from "lodash/debounce"
export default {
  name: 'App',
  data() {
    return {
      deviceNumber: navigator.userAgent,
      refreshLoading: false,
      noData: false,
      hxUsername: '',
      hxPassword: '',
      avatarUrl: '',
      chatFlag: false,
      imgFlag: false,
      imgSrc: '',
      messageContent: '',
      groupId: '',
      message: [],
      msgId: -1,
      preScrollHeight: 0,
      sendFlag: true,
      // 介绍页
      isShowIntro: true,
      isShowShareImg: false
    }
  },
  filters: {
    // 时间
    timeFilter: function (timestamp) {
      return timeChange(timestamp)
    }
  },
  created () {
    
  },
  mounted() {
    // 去掉打印
    WebIM.logger.disableAll()
    // 监听查看会话滚动事件
    let messageListNode = this.$refs['content']
    messageListNode.addEventListener('scroll', () => {
      if (messageListNode.scrollTop === 0) {
        if (this.noData) {
          return
        }
        this.preScrollHeight = messageListNode.scrollHeight
        this.sendFlag = false
        this.scrollDebounce()
      }
    })
  },
  updated() {
  },
  computed: {
    langObj() {
      return this.$store.getters.langObjData
    }
  },
  watch: {
    // 主页显示客服
    '$store.getters.getCustomerService': {
      handler(newValue) {
        if (newValue) {
          this.goChat()
        }
      }
    },
    '$route': {
      handler(to) {
        if (to.path.toLocaleUpperCase() === '/ABOUTUS' || to.path.toLocaleUpperCase() === '/GETSTARTED' || to.path.toLocaleUpperCase().includes('/CRYPTOACADEMY')) {
          this.isShowIntro = true
        } else {
          if (this.isShowIntro) this.isShowIntro = false
        }
      },
      immediate: true
    }
  },
  methods: {
    // 关闭客服
    closeChatImg() {
      this.chatFlag = false
      this.$store.dispatch('getCustomerService', { isShow: false })
    },
    /* 滚动条的防抖 */
    scrollDebounce: debounce(function() {
      this.historyMsg()
    }, 500),
    // 获取用户信息
    getUserInfo() {
      this.refreshLoading = true
      var data = {}
      this.http(
        this.ApiSetting.userRegister,
        data,
        { deviceNumber: this.deviceNumber }
      ).then((res) => {
        if (res.data.statusCode === '000000') {
          this.hxUsername = res.data.data.hxUsername
          this.hxPassword = res.data.data.hxPassword
          this.avatarUrl = res.data.data.avatarUrl
          this.getGroupChat()
        }
      }, error => {
        console.log(error)
      })
    },
    // 创建群组
    getGroupChat() {
      var data = {
        chatType: 2,
        hxUsername: this.hxUsername,
      }
      this.http(
        this.ApiSetting.getGroupChat,
        data,
        { deviceNumber: this.deviceNumber }
      ).then((res) => {
        if (res.data.statusCode === '000000') {
          this.groupId = res.data.data
          this.chat()
        }
      }, error => {
        console.log(error)
      })
    },
    chat () {
      var appKey
      if (process.env.VUE_APP_ENV === 'dev' || process.env.VUE_APP_ENV === 'test') {
        appKey = process.env.VUE_APP_Key
      } else {
        appKey = process.env.VUE_APP_Key
      }
      /* eslint-disable */
      WebIM.conn = new WebIM.connection({
        appKey: appKey,
      })
      WebIM.conn.addEventHandler('connection&message', {
        onConnected: () => {
          // console.log('连接成功')
        },
        onDisconnected: () => {
        },
        onTextMessage: (message) => {
          this.queryMessage(message)
        },
        onImageMessage: (message) => {
          this.queryMessage(message)
        },
        onCustomMessage: (message) => {
          this.queryMessage(message)
        },
        onError: () => {
        },
        onReactionChange: (reactionMsg) => {
          this.message.forEach((item, index) => {
            if (reactionMsg.messageId === item.id) {
              this.message.splice(index, 1, reactionMsg)
            }
          })
        }
      })
      WebIM.conn
        .open({ user: this.hxUsername, pwd: this.hxPassword })
        .then(() => {
          this.historyMsg()
        })
        .catch(() => {
        })
    },
    queryMessage (message) {
      if (message.to === this.groupId) {
        this.message.push(message)
        this.sendFlag = true
        // 点击当前行的未读计数清为0
        let option = {
          chatType: 'groupChat', // 会话类型，设置为群聊。
          type: 'channel',       // 消息类型。
          to: this.groupId        // 消息接收方（群组 ID)。
        }
        let msg = WebIM.message.create(option)
        WebIM.conn.send(msg)
        this.$nextTick(() => {
          let messageListNode = this.$refs['content']
          if (messageListNode) {
            messageListNode.scrollTop = messageListNode.scrollHeight
          }
        })
      }
    },
    // 拉取历史消息 
    historyMsg () {
      this.refreshLoading = true
      var options = {
        targetId: this.groupId,
        pageSize: 10,
        cursor: this.msgId,
        chatType:'groupChat',
        searchDirection: 'up',
      }
      WebIM.conn.getHistoryMessages(options).then((res)=>{
        // console.log(res)
        if (this.sendFlag) {
          this.message = []
          this.message = res.messages.reverse()
          this.msgId = this.message.length !== 0 ? this.message[0].id : ''
          if (!res.messages.length) {
            this.noData = true
            this.refreshLoading = false
          }
          setTimeout(()=>{
            let messageListNode = this.$refs['content']
            if (messageListNode) {
              messageListNode.scrollTop = messageListNode.scrollHeight
            }
            this.refreshLoading = false
          }, 1000)
        } else {
          this.message = res.messages.reverse().concat(this.message)
          this.msgId = this.message[0].id
          if (!res.messages.length) {
            this.noData = true
          }
          setTimeout(()=>{
            let messageListNode = this.$refs['content']
            if (messageListNode) {
              messageListNode.scrollTop = messageListNode.scrollHeight - this.preScrollHeight - 80
            }
            this.refreshLoading = false
          }, 1000)
        }
        // 点击当前行的未读计数清为0
        let option = {
          chatType: 'groupChat', // 会话类型，设置为群聊。
          type: 'channel',       // 消息类型。
          to: this.groupId        // 消息接收方（群组 ID)。
        }
        let msg = WebIM.message.create(option)
        WebIM.conn.send(msg)
      }).catch(()=>{
        // 获取失败。
      })
    },
    // 发送消息
    sendMsg () {
      if (!this.messageContent.trim()) {
        return
      }
      let option = {
        chatType: 'groupChat',    // 会话类型，设置为群聊。
        type: 'txt',               // 消息类型。
        to: this.groupId,              // 消息接收方（用户 ID)。
        msg: this.messageContent,     // 消息内容。
        ext: {
          avatarUrl: this.avatarUrl
        }
      }
      let msg = WebIM.message.create(option)
      WebIM.conn.send(msg).then(() => {
        this.sendMsgsort(msg)
      }).catch((e) => {
        console.log(e)
      })
    },
    // 发送图片
    sendImage() {
      let input = document.getElementById('imagePicker');  // 选择要发送的图片。
      let file = WebIM.utils.getFileUrl(input);      // 将图片转换为 fileObj 格式。
      // 也可将文件自行处理成该格式。
      // let file = {
      //    data: file              file 对象。
      //    filename: 'fileName',   文件名称。
      //    filetype: 'filetype',   文件类型。
      // }
      let allowType = {
        'jpg': true,
        'gif': true,
        'png': true,
        'bmp': true,
        'jpeg': true
      }
      if (file.filetype.toLowerCase() in allowType) {
        let option = {
          chatType: 'groupChat',    // 会话类型，设置为单聊。
          type: 'img',               // 消息类型，设置为图片。
          to: this.groupId,              // 消息接收方（用户 ID)。
          ext: {
            avatarUrl: this.avatarUrl
          },
          file: file,
          onFileUploadError: () => { 
            // 消息上传失败。     
            this.$message({
              message: this.langObj['上传失败'],
              duration: 2000,
              type: 'warning'
            })
          },
          onFileUploadProgress: () => {
            // 上传进度的回调。
          },
          onFileUploadComplete: () => {  
            // // 消息上传成功。 
          }
        }
        let msg = WebIM.message.create(option); 
        WebIM.conn.send(msg).then(() => {
          this.sendMsgsort(msg)
        }).catch(() => {
          this.$message({
            message: this.langObj['上传失败'],
            duration: 1000,
            type: 'warning'
          })
        })
      }
    },
    // 发完消息后push到页面上显示
    sendMsgsort (msg) {
      this.messageContent = ''
      msg.from = this.hxUsername
      this.message.push(msg)
      this.sendFlag = true
      this.$nextTick(() => {
        let messageListNode = this.$refs['content']
        if (messageListNode) {
          messageListNode.scrollTop = messageListNode.scrollHeight
        }
      })
    },
    focusDiv () {
      this.sendFlag = true
    },
    // 查看大图
    handlePreview(imageUrl) {
      this.imgFlag = true
      this.imgSrc = imageUrl
      this.$nextTick(() => {
        var myImg = document.querySelector(".layer-img")
        var currWidth = myImg.clientWidth
        var currHeight = myImg.clientHeight
        if (currWidth >= currHeight) {
          document.querySelector(".layer-img").style.width = '375px'
        } else {
          document.querySelector(".layer-img").style.height = '100%'
        }
      })
    },
    // 点击图标去聊天
    goChat () {
      this.getUserInfo()
      this.chatFlag = true
      this.message = []
      this.msgId = -1
      this.sendFlag = true
      this.noData = false
    },

    // 介绍页方法
    shareHandleClick(e) {
      window.addEventListener('click', () => {
        this.isShowShareImg = false
      })
      if (this.isShowShareImg) {
        this.isShowShareImg = false
      } else {
        this.isShowShareImg = true
      }
    },
    shareHandleMouseOver() {
      if (!this.isShowShareImg && !this.$device.mobile) this.isShowShareImg = true
    },
    shareHandleMouseOut() {
      if (!this.$device.mobile) this.isShowShareImg = false
    },
    jumpShareClick(value) {
      let jumpUrl = ''
      switch (value) {
        case 'tw':
          jumpUrl = 'https://twitter.com/S6GlobalEx'
          break
        case 'ins':
          jumpUrl = 'https://instagram.com/s6globalex'
          break
        case 'fb':
          jumpUrl = 'https://facebook.com/S6GlobalExchange'
          break
        case 'tel':
          jumpUrl = 'https://t.me/S6Ex2_CN'
          break
        case 'tt':
          jumpUrl = 'https://www.tiktok.com/@s6globalex'
          break
        case 'ytb':
          jumpUrl = 'https://www.youtube.com/@s6globalexchange964'
          break
        case 'in':
          jumpUrl = 'linkedin.com/company/s6-global-exchange?trk=similar-pages'
          break
      }
      window.open(jumpUrl)
    }
  },
  beforeDestroy: function () {
    WebIM.conn.close()
  }
}
</script>

<style lang="scss" scoped>
.current-conversation {
  display: flex;
  flex-direction: column;
  width: 375px;
  height: 484px;
  background: #fff;
  justify-content: space-between;
  position: fixed;
  z-index: 9999999;
  bottom: 68px;
  right: 40px;
  box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.1);
}
.chat-title-box {
  width: 375px;
  height: 36px;
  background: #12161C;
  border-radius: 5px 5px 0px 0px;
  line-height: 36px;
}
.chat-title {
  color: #FFFFFF;
  font-size: 16px;
  padding-left: 10px;
  font-weight: bolder;
}
.chat-img {
  position: absolute;
  right: 10px;
  top: 8px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.content {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  padding-bottom: 18px;
}
.line-box {
  display: flex;
  align-items: flex-end;
  margin-top: 18px;
  .head-portrait {
    width: 35px;
    height: 35px;
    margin-bottom: 40px;
    margin-left: 14px;
  }
  .base-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 8px;
  }
  .time {
    font-size: 12px;
    color: #a2a5ae;
  }
  .msg-box {
    position: relative;
    margin-top: 8px;
  }
  .msg-txt {
    display: inline-block;
    font-size: 14px;
    max-width: 189px;
    color: #464650;
    background: #FFFFFF;
    border-radius: 8px 8px 8px 2px;
    border: 1px solid #DEDFE1;
    padding: 10px 12px;
    word-wrap: break-word;
    word-break: normal;
  }
  .official-news {
    background: #D9D9D9;
    border-radius: 8px;
    display: inline-block;
    color: #FFFFFF;
    font-size: 12px;
    padding: 2px 5px;
    text-align: center;
    margin-top: 10px;
  }
}
.line-box-right {
  display: flex;
  align-items: flex-start;
  margin-top: 18px;
  flex-direction: row-reverse;
  .head-portrait {
    width: 35px;
    height: 35px;
    margin-right: 14px;
  }
  .base-info {
    display: flex;
    flex-direction: column;
    margin-right: 8px;
    align-items: flex-end;
  }
  .time {
    font-size: 12px;
    color: #a2a5ae;
    text-align: right;
  }
  .msg-box {
    position: relative;
    margin-top: 8px;
  }
  .msg-txt {
    display: inline-block;
    font-size: 14px;
    max-width: 189px;
    color: #fff;
    background: #24C97F;
    border-radius: 8px 8px 2px 8px;
    border: 1px solid #DEDFE1;
    padding: 10px 12px;
    word-wrap: break-word;
    word-break: normal;
  }
}
.msg-img {
  width: 100px;
  height: 100%;
  vertical-align: middle;
  cursor: pointer;
}
.txt-center {
  text-align: center;
  font-size: 12px;
  color: #9C9FA6;
  width: 100%;
}
.footer {
  display: flex;
  align-items: center;
  width: 375px;
  background: #fff;
  padding: 8px 0 11px 0;
  box-shadow: 0px -2px 4px 0px rgba(0,0,0,0.06);
}
.add-box {
  margin-left: 4px;
  margin-right: 2px;
  position: relative;
}
.add {
  width: 25px;
  height: 25px;
  vertical-align: middle;
}
.text-input {
  border-radius: 18px 18px 18px 18px;
  border: 0.5px solid #9aa1b1;
  outline: none;
	display: inline-block;
	width: 255px;
	font-size: 14px;
  height: 27px;
  line-height: 17px;
  color: #606266;
  resize: none;
  padding: 4px 8px 0 8px;
  background-color: #fff;
  overflow-y: auto;
}
.send-box {
  margin-left: 10px;
  cursor: pointer;
}
.send {
  display: inline-block;
  width: 72px;
  height: 27px;
  background: #24C97F;
  color: #FFFFFF;
  font-size: 14px;
  border-radius: 14px;
  text-align: center;
  line-height: 27px;
}
.send1 {
  display: inline-block;
  width: 72px;
  height: 27px;
  background: #DDE0E5;
  color: #FFFFFF;
  font-size: 14px;
  border-radius: 14px;
  text-align: center;
  line-height: 27px;
}
#imagePicker {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 14px;
}
.img-load {
  width: 24px;
  height: 24px;
  animation: rotating 2s linear infinite;
  margin-right: 10px;
}
.element::-webkit-scrollbar { width: 0 !important }
.go-chat {
  position: fixed;
  z-index: 10000;
  bottom: 68px;
  right: 40px;
  width: 54px;
  height: 54px;
  background:#f7b239;
  border-radius: 50px;
  cursor: pointer;
}
.layer-box {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0,0,0,.8);
  z-index: 5999;
}
.layer-img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.current-conversation ::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 0; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
.current-conversation ::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  background: rgba(0,0,0,0.8);
}
.current-conversation ::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border-radius: 10px;
  background: #fff;
}

/* 介绍页图片 */
.intro-imgs {
  position: fixed;
  right: 40px;
  bottom: 100px;
  display: flex;
  flex-direction: column;
  z-index: 999;
  > div {
    width: 54px;
    height: 54px;
    img {
      position: relative;
      width: 100%;
      height: 100%;
      z-index: 9;
      cursor: pointer;
    }
  }
  &.mobile-img {
    right: 0.2rem;
    bottom: 0.4rem;
    z-index: 9;
    > div {
      width: 0.54rem;
      height: 0.54rem;
    }
  }
  div+div {
    margin-top: 24px;
  }
  .share {
    position: relative;
    .share-list {
      position: absolute;
      left: 50%;
      bottom: 20px;
      width: 46px;
      transform: translateX(-50%);
      background: #F1B31C;
      padding-top: 20px;
      padding-bottom: 43px;
      border-radius: 24px 24px 0 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 18px;
        cursor: pointer;
      }
      img+img {
        margin-top: 12px;
      }
    }
  }
}
</style>

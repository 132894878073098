
// 数据扩大100倍(保留两位小数，不四舍五入)
function expand100 (val) {
  if (val !== undefined) {
    if (typeof val === 'string') {
      if (val === '') {
        return ''
      } else {
        if (val === '0') {
          return val
        } else {
          if (val < 0) {
            return -(parseFloat((-(Number(val) * 100).toFixed()).toString().match(/^\d+(?:\.\d{0,2})?/)[0]))
          } else {
            return parseFloat(((Number(val) * 100).toFixed()).toString().match(/^\d+(?:\.\d{0,2})?/)[0])
          }
        }
      }
    } else {
      if (val === 0) {
        return 0
      } else {
        if (val < 0) {
          return -(parseFloat((-val * 100).toFixed().toString().match(/^\d+(?:\.\d{0,2})?/)[0]))
        } else {
          return parseFloat((val * 100).toFixed().toString().match(/^\d+(?:\.\d{0,2})?/)[0])
        }
      }
    }
  } else {
    return ''
  }
}

// 数据缩小100倍(保留两位小数，不四舍五入)
function narrow100 (val) {
  if (val !== undefined) {
    if (typeof val === 'string') {
      if (val === '') {
        return ''
      } else {
        if (val === '0') {
          return val
        } else {
          if (val < 0) {
            return -(parseFloat((-(Number(val) / 100)).toString().match(/^\d+(?:\.\d{0,2})?/)[0]))
          } else {
            return parseFloat((Number(val) / 100).toString().match(/^\d+(?:\.\d{0,2})?/)[0])
          }
        }
      }
    } else {
      if (val === 0) {
        return 0
      } else {
        if (val < 0) {
          return -(parseFloat((-val / 100).toString().match(/^\d+(?:\.\d{0,2})?/)[0]))
        } else {
          return parseFloat((val / 100).toString().match(/^\d+(?:\.\d{0,2})?/)[0])
        }
      }
    }
  } else {
    return ''
  }
}

// 千分位分隔符
function thousandthCharacter (val) {
  if (val === 0) {
    return 0
  } else {
    if (val < 0) {
      if ((val + '').includes('.')) {
        let money = ((-(Number(val))).toString().match(/^\d+(?:\.\d{0,2})?/)[0]).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,')
        return '-' + money
      } else {
        return -((-val + '').replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,'))
      }
    } else {
      if ((val + '').includes('.')) {
        return (Number(val).toString().match(/^\d+(?:\.\d{0,2})?/)[0]).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,')
      } else {
        return (val + '').replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,')
      }
    }
  }
}
// 科学计数法
function toolNumber(val) {
  if (val?.toString().length < 20) {
    val = parseFloat(val)
    let exponentialNumReg = /\d(.(\d+))?[eE]{1}[+-]?([1-9]\d*)/
    let result = exponentialNumReg.exec(val.toString())
    // exponentialNumReg.exec('3.33300e-8')
    // Result -> ["3.33300e-8", ".33300", "33300", "8", index: 0, input: "3.33300e-8"]
    let length = 0
    if (result && result.length > 0) {
      length = (result[2] ? result[2].length : 0) + parseInt(result[3])
    }
    return length === 0 ? String(val) : val.toFixed(length);
  } else {
    return val
  }
}

// 保留小数位
function keepDecimal(price) {
  const strPrice = this.toolNumber(price)
  let n = 0
  if (strPrice > 10) {
    n = 2
  } else if (strPrice < 10 && strPrice > 1) {
    n = 3
  } else if (strPrice < 1 && strPrice > 0) {
    const a = strPrice.slice(2).indexOf('0')
    const b = strPrice.lastIndexOf('0')
    if (a < 3 || b > a) {
      if (strPrice.indexOf('0000') === 2) {
        n = b + 3
      } else {
        n = 4
      }
    } else {
      n = b + 3
    }
  }
  return Math.round(strPrice * Math.pow(10, n)) / Math.pow(10, n)
}

export {
  expand100,
  narrow100,
  thousandthCharacter,
  toolNumber,
  keepDecimal
}

<template>
  <svg :width="width" :height="height" viewBox="0 0 47 52" fill="currentcolor" xmlns="http://www.w3.org/2000/svg">
    <path d="M23.381 28.5909C15.4756 28.5874 9.07825 22.1778 9.08815 14.2696C9.10037 6.39448 15.5228 -0.00523482 23.413 3.21312e-06C31.3224 0.00640525 37.7274 6.41368 37.7221 14.3138C37.7169 22.2139 31.3055 28.5944 23.381 28.5909ZM23.4066 5.72226C21.134 5.71948 18.9533 6.61903 17.3436 8.22326C15.7339 9.82749 14.827 12.0051 14.822 14.2777C14.7999 18.9919 18.6429 22.8564 23.374 22.8716C24.5019 22.8756 25.6195 22.6573 26.663 22.2292C27.7065 21.801 28.6553 21.1714 29.4553 20.3763C30.2553 19.5812 30.8907 18.6362 31.3252 17.5953C31.7597 16.5545 31.9848 15.4382 31.9877 14.3103C32.0016 9.57222 28.1604 5.72692 23.4066 5.72226Z"/>
    <path d="M23.1721 31.4603C26.7432 31.4736 30.2271 31.9945 33.5492 33.35C39.0346 35.5884 43.0737 39.3633 45.535 44.7771C46.117 46.0575 46.5197 47.3897 46.5075 48.8168C46.4953 50.2089 45.7416 51.1384 44.4024 51.427C43.0993 51.7082 41.8707 51.1145 41.3178 49.9208C40.2166 47.5305 38.8902 45.2852 37.1343 43.3093C34.1574 39.9592 30.4826 37.8908 26.0181 37.3309C20.9773 36.6994 16.415 37.9333 12.3724 41.008C9.6168 43.0953 7.36495 45.7746 5.78296 48.8482C5.60487 49.1916 5.44947 49.5466 5.30514 49.908C4.84419 51.0557 3.80706 51.6599 2.56972 51.4882C0.692757 51.2292 -0.310617 49.7707 0.0857278 47.8797C0.230647 47.1895 0.53678 46.5592 0.838258 45.9312C2.70765 42.0399 5.27604 38.7027 8.73546 36.0895C12.0622 33.577 15.8167 32.1261 19.9513 31.6418C21.0203 31.5171 22.0958 31.4564 23.1721 31.4603Z"/>
  </svg>
</template>

<script>
  export default {
    props: {
      width: {
        default: '32'
      },
      height: {
        default: '36'
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>
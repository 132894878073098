import vue from '../../main'

const pageData = {
  state: {
    personalInfo: {},
    showWorth: true,
    showCustomerService: false,
    countryOptionsData: [],
    introPagePath: '',
    isShowIntroSignUp: false
  },
  getters: {
    personalInfoData(state) {
      return state.personalInfo
    },
    getShowWorthData(state) {
      return state.showWorth
    },
    getCustomerService(state) {
      return state.showCustomerService
    },
    getCountryOptionsData(state) {
      return state.countryOptionsData ?? []
    },
    getIntroPagePathData (state) {
      return state.introPagePath ?? ''
    },
    getIsShowIntroSignUpData (state) {
      return state.isShowIntroSignUp
    }
  },
  mutations: {
    changePersonalInfoVal(state, val) {
      state.personalInfo = val
    },
    changeShowWorth(state, val) {
      state.showWorth = val
    },
    changeCustomerService(state, val) {
      state.showCustomerService = val
    },
    changeCountryOptions(state, val) {
      state.countryOptionsData = val
    },
    changeIntroPagePath (state, val) {
      state.introPagePath = val
    },
    changeIsShowIntroSignUp (state, val) {
      state.isShowIntroSignUp = val
    }
  },
  actions: {
    async getPersonalInfoAction({ commit }) {
      await vue.http(
        vue.ApiSetting.memberInfo,
        {}
      ).then((res) => {
        if (res.data.statusCode === '000000') {
          const val = res.data.data
          val.showPhone = false
          val.showEmail = false
          val.showGoogle = false
          val.verifyParameters.forEach(item => {
            switch (item) {
              case '1':
                val.showPhone = true
                break
              case '2':
                val.showEmail = true
                break
              case '3':
                val.showGoogle = true
                break
            }
          })
          val.showHorizBar = []
          // if (val.showPhone === true) {
          //   val.showHorizBar.push(1)
          // } else {
          //   val.showHorizBar.push(0)
          // }
          if (val.showEmail === true) {
            val.showHorizBar.push(1)
          } else {
            val.showHorizBar.push(0)
          }
          if (val.showGoogle === true) {
            val.showHorizBar.push(1)
          } else {
            val.showHorizBar.push(0)
          }
          val.showHorizBar.sort((a, b) => {
            return b - a
          })
          val.safetyLevel = val.verifyParameters.length
          commit('changePersonalInfoVal', { ...val })
        }
      })
    },
    getShowWorthAction({ commit }, payload) {
      commit('changeShowWorth', payload.showWorth)
    },
    getCustomerService({ commit }, payload) {
      commit('changeCustomerService', payload.isShow)
    },
    getCountryOptionsAction({ commit }, payload) {
      commit('changeCountryOptions', payload.data)
    },
    getIntroPagePathAction ({ commit }, payload) {
      commit('changeIntroPagePath', payload.path)
    },
    getIntroSignUpAction ({ commit }, payload) {
      commit('changeIsShowIntroSignUp', payload.isShowIntroSignUp)
    }
  }
}

export { pageData }

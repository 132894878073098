<template>
  <svg :width="width" :height="height" :viewBox="`0 0 ${width + 1} ${height + 1}`" fill="currentcolor" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1768_12998)">
      <path d="M18.4204 0.00221634C7.93803 0.172752 -0.175009 8.45955 0.00223913 18.8152C0.175 28.9555 8.57747 37.1548 18.6245 36.991C28.8309 36.8317 37.1413 28.408 36.991 18.3665C36.8452 8.1454 28.4472 -0.161591 18.4204 0.00221634ZM12.7372 4.45191L12.7821 4.47211H12.708C12.7327 4.61123 12.8224 4.77952 12.7731 4.88274C11.7455 7.04588 11.2025 9.35936 10.7291 11.6818H4.99884C6.73318 8.24638 9.38966 5.98002 12.7372 4.45191ZM12.809 32.3775C12.0259 32.3393 11.4224 31.8659 10.7897 31.4934C8.28135 30.034 6.26158 27.8641 4.98538 25.2575H10.774C11.0836 27.7618 11.9923 30.0685 12.7933 32.4089L12.8314 32.3887L12.809 32.3775ZM13.7491 21.7907C13.5551 19.6 13.5633 17.3962 13.7738 15.207H23.5359C23.6826 17.3995 23.6721 19.5997 23.5045 21.7907H13.7491ZM23.0378 25.2553C22.589 27.8179 21.916 30.248 20.3948 32.3416C19.1809 34.0066 18.0748 33.9931 16.8588 32.3416C15.3196 30.248 14.7049 27.8021 14.2158 25.2553H23.0378ZM14.2561 11.7065C14.6937 8.95322 15.5777 6.44451 16.6277 4.92762C18.0412 2.88565 19.2819 2.87219 20.6438 4.96577C21.9631 6.98529 22.6317 9.27185 23.0153 11.6975L14.2561 11.7065ZM10.3701 21.7682H3.71997C3.2284 19.5949 3.23761 17.3382 3.74689 15.1689H10.3522C10.1843 17.3657 10.1903 19.5724 10.3701 21.7682ZM33.2127 15.1487C33.7041 17.4285 33.7691 19.6141 33.188 21.775H27.0135V15.1487H33.2127ZM26.5513 25.2665H31.8463C30.8546 28.0445 27.0449 31.6123 24.4423 32.1733C25.5058 29.9518 26.0443 27.6024 26.5401 25.2755L26.5513 25.2665ZM24.3391 4.53045C27.8594 6.16402 30.296 8.42814 31.9451 11.6796H26.5244C26.0488 9.29654 25.4766 6.99652 24.3279 4.53942L24.3391 4.53045Z"/>
    </g>
    <!-- <defs>
      <clipPath id="clip0_1768_12998">
        <rect :width="36" :height="36"/>
      </clipPath>
    </defs> -->
  </svg>
</template>

<script>
  export default {
    props: {
      width: {
        default: 37
      },
      height: {
        default: 37
      }
    }
  }
</script>

<style scoped>
</style>
<template>
  <svg width="12" height="6" viewBox="0 0 14 9" fill="currentcolor" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0H14V3L7 9L0 3V0Z"/>
  </svg>
</template>

<script>
  export default {
    
  }
</script>

<style scoped>

</style>
import Vue from 'vue'
import App from './App.vue'
import router from './router.js'
import $ from '../public/static/js/jquery.min'
import VueI18n from 'vue-i18n'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import store from './store/store.js'
import moment from "moment";
import VueDeviceDetector from 'vue-device-detector';//检测设备类型插件
// // 对axios的二次封装
import Http from './utils/axios/http.js'
import ApiSetting from './utils/axios/apiSetting'
import axios from 'axios'
// 过滤器
import * as filters from './utils/filters'
import md5 from 'js-md5'
import './assets/images/font/style.css'
import './assets/font/font.css'
import './flexible.js';
require('es6-promise').polyfill()
require('es6-promise/auto')
Vue.prototype.$store = store
Vue.prototype.http = Http
Vue.prototype.ApiSetting = ApiSetting
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})
Vue.prototype.filters = filters
Vue.prototype.host = process.env.VUE_APP_BASIC_API1
axios.defaults.headers.common['lang'] = store.state.languageType
Vue.prototype.$md5 = md5
Vue.config.productionTip = false
Vue.prototype.dateMoment = (time, string = 'YYYY-MM-DD') => {
  if (!time) {
    return
  }
  return moment(time).format(string)
}
Vue.use(VueI18n)
Vue.use(ElementUI)
Vue.use(VueDeviceDetector)
const i18n = new VueI18n({
  locale: 'zh_CN',
  messages: {
    'zh_CN': require('./assets/lang/zh.js'),
    'en': require('./assets/lang/en.js')
  },
  silentTranslationWarn: true
})
var vue = new Vue({
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')

export default vue

<template>
  <div class="page">
    <div v-show="!isShowIntro && showHeader" class="page-header">
      <div class="left-pages">
        <div class="logo-box">
          <img
            class="logo"
            src="../assets/images/common/logo.png"
            alt=""
            @click="jumpActive(0)"
          />
        </div>
        <div class="pages-routes">
          <ul>
            <li :class="active == 1 ? 'isActive' : ''" @click="jumpActive(1)">
              <span>{{ langObj["行情"] }}</span>
            </li>
            <li :class="active == 2 ? 'isActive' : ''" @click="jumpActive(2)">
              <span>{{ langObj["现货交易"] }}</span>
            </li>
            <li :class="active == 3 ? 'isActive' : ''" @click="jumpActive(3)">
              <span>{{ langObj["U本位合约"] }}</span>
            </li>
            <li :class="active == 4 ? 'isActive' : ''" @click="jumpActive(4)">
              <span>{{ langObj["币本位合约"] }}</span>
            </li>
            <li :class="active == 5 ? 'isActive' : ''" @click="jumpActive(5)">
              <span>{{ langObj["金融理财"] }}</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="right-menus">
        <span
          class="login"
          v-if="!isLogin"
          :class="active == 6 ? 'isActive' : ''"
          @click="jumpActive(6)"
          >{{ langObj["登录"] }}</span
        >
        <span class="register" v-if="!isLogin" @click="register">{{
          langObj["注册"]
        }}</span>
        <div
          class="user-box"
          v-if="isLogin"
          @mouseover="mouseOver"
          @mouseleave="mouseLeave"
        >
          <img class="user-img" src="../assets/images/common/user.png" alt="" />
          <img
            class="user-active"
            src="../assets/images/common/user-active.png"
            alt=""
          />
          <span class="user-txt">{{ langObj["用户中心"] }}</span>
          <div class="user-more" v-show="userFlag">
            <div class="user-line" @click="jumpPage('geren')">
              <i class="icon-gerenzonglan menu-icon"></i>
              <span
                class="user-line-txt"
                :class="activeA === 'geren' ? 'active-second' : ''"
                >{{ langObj["个人总览"] }}</span
              >
            </div>
            <div class="user-line" @click="jumpPage('zichan')">
              <i class="icon-asset menu-icon"></i>
              <span
                class="user-line-txt"
                :class="activeA === 'zichan' ? 'active-second' : ''"
                >{{ langObj["资产管理"] }}</span
              >
            </div>
            <div class="user-line" @click="jumpPage('trade')">
              <i class="icon-transaction2 menu-icon"></i>
              <span
                class="user-line-txt"
                :class="activeA === 'trade' ? 'active-second' : ''"
                >{{ langObj["交易管理"] }}</span
              >
            </div>
            <div class="user-line" @click="jumpPage('licai')">
              <i class="icon-financial menu-icon"></i>
              <span
                class="user-line-txt"
                :class="activeA === 'licai' ? 'active-second' : ''"
                >{{ langObj["理财管理"] }}</span
              >
            </div>
            <div class="user-line" @click="jumpPage('tuiguang')">
              <i class="icon-promotion menu-icon"></i>
              <span
                class="user-line-txt"
                :class="activeA === 'tuiguang' ? 'active-second' : ''"
                >{{ langObj["推广中心"] }}</span
              >
            </div>
            <div class="user-line" @click="jumpPage('kaquan')">
              <i class="icon-card menu-icon"></i>
              <span
                class="user-line-txt"
                :class="activeA === 'kaquan' ? 'active-second' : ''"
                >{{ langObj["卡券中心"] }}</span
              >
            </div>
            <div class="user-line" @click="jumpPage('zhangdan')">
              <i class="icon-bill menu-icon"></i>
              <span
                class="user-line-txt"
                :class="activeA === 'zhangdan' ? 'active-second' : ''"
                >{{ langObj["账单"] }}</span
              >
            </div>
            <div class="user-line" @click="jumpPage('out')">
              <i class="icon-out menu-icon"></i>
              <span class="user-line-txt">{{ langObj["退出登录"] }}</span>
            </div>
          </div>
        </div>
        <div class="download">
          <div class="download-father">
            <img
              class="download-img"
              src="../assets/images/common/download.png"
              alt=""
            />
            <img
              class="download-active"
              src="../assets/images/common/download-active.png"
              alt=""
            />
            <span class="download-span">{{ langObj["APP下载"] }}</span>
          </div>
          <div class="download-box">
            <span class="download-txt">{{
              langObj["扫码下载iOS&Android APP"]
            }}</span>
            <div class="download-phone-box">
              <div class="download-phone-father">
                <div class="download-phone">
                  <img
                    class="download-phone-img"
                    src="../assets/images/common/ios.png"
                    alt=""
                  />
                  <span class="download-phone-txt">iPhone</span>
                </div>
                <div class="download-phone download-phone-two">
                  <img
                    class="download-phone-img"
                    src="../assets/images/common/android.png"
                    alt=""
                  />
                  <span class="download-phone-txt">Android</span>
                </div>
              </div>
              <div id="qrcode" ref="qrcode" class="download-erweima" />
            </div>
          </div>
        </div>
        <div class="lang-box" @mouseover="mouseOver1" @mouseleave="mouseLeave1">
          <div class="lang-father">
            <span class="lang-span">{{ internationalCurrency }}</span>
            <img
              class="lang-img"
              src="../assets/images/common/bottom.png"
              alt=""
            />
            <img
              class="lang-active"
              src="../assets/images/common/top.png"
              alt=""
            />
          </div>
          <div class="lang-more" v-show="langCoinFlag">
            <div
              class="lang-line"
              @click="jumpCoin(item)"
              v-for="(item, index) in internationalCurrencyArr"
              :key="index"
            >
              <img class="lang-line-img" :src="item.imageUrl" alt="" />
              <span class="lang-line-txt">{{ item.code }}</span>
            </div>
          </div>
        </div>
        <div class="lang-box-sel" @click="chooseLang()">
          <img
            class="lang-internationalCurrency-img"
            v-if="$store.state.languageFace"
            :src="$store.state.languageFace"
            alt=""
          />
          <span class="lang-span-sel">{{ $store.state.languageContent }}</span>
        </div>
      </div>
    </div>

    <!-- 介绍页顶部栏 Mobile端-->
    <div
      v-if="$device.mobile && isShowIntro"
      class="intro-header-mobile"
      :class="{ 'scroll-style-mobile': !isTop }"
    >
      <div class="header-content-mobile">
        <div class="header-logo-mobile">
          <IntroLogo width="1.5rem" height="0.34rem"></IntroLogo>
        </div>

        <div class="switch-lang-mobile" @click="langhandle">
          <el-popover
            placement="bottom"
            width="200"
            trigger="click"
            v-model="langPop"
          >
            <div class="lang-wrapper-mobile" slot="reference">
              <div class="lang-text-mobile">
                {{ $store.state.languageType.toLocaleUpperCase() }}
              </div>
            </div>
            <div class="info-tip-mobile">
              <div
                v-for="(item, index) in languageTypeData"
                :key="item.LanguageType"
                class="tip-content-mobile"
                :class="{ 'tip-active-mobile': tipActive === index }"
                @click="langClickHandleMobile(item, index)"
              >
                <p
                  class="tip-header-mobile Montserrat"
                  :class="{ 'tip-header-active-mobile': tipActive === index }"
                  style="
                    font-size: 0.25rem;
                    font-weight: 600;
                    line-height: 0.29rem;
                    color: #505050;
                  "
                >
                  {{ item.LanguageContent }}
                </p>
                <span class="indicator-mobile"></span>
              </div>
            </div>
          </el-popover>
          <el-tooltip effect="light" placement="bottom"> </el-tooltip>
        </div>
        <div class="intro-download-mobile" @click="downloadClick">
          <img src="@/assets/images/index/intro-download.png" alt="" />
        </div>
        <div class="more-mobile" @click="showMore">
          <img
            :src="require('@/assets/images/intro/commonMobile/more@2x.png')"
            class="more"
          />
        </div>
      </div>
    </div>

    <!-- 介绍页顶部栏 PC端-->
    <div
      v-else-if="!$device.mobile && isShowIntro"
      class="intro-header"
      :class="{ 'scroll-style': !isTop }"
    >
      <div class="header-content">
        <div class="header-logo" @click="introJumpOtherPageClick(0)">
          <IntroLogo width="180" height="43"></IntroLogo>
        </div>
        <div class="content-right">
          <div class="intro-page">
            <div
              class="page-name"
              :class="{
                'intro-active': introActive === 0,
                introHover: introHover === 0,
              }"
              @mouseover="introHandleMouseOver($event, 0)"
              @mouseout="introHandleMouseOut($event)"
              @click="introFooterJumpClick('Who we are')"
            >
              <el-tooltip
                popper-class="intro-tooltip"
                effect="light"
                placement="bottom"
              >
                <div>
                  <span class="Montserrat">{{ langObj["About us"] }}</span>
                  <ArrowBottom></ArrowBottom>
                </div>
                <div slot="content">
                  <div class="info-tip">
                    <div
                      class="tip-content"
                      :class="{ 'tip-active': tipActive === 0 }"
                      @mouseover="tipHandleMouseOver($event, 0)"
                      @mouseout="tipHandleMouseOut($event)"
                      @click="introFooterJumpClick('Who we are')"
                    >
                      <p class="tip-header Montserrat">
                        {{ langObj["Who are we?"] }}
                      </p>
                      <ul class="tip-list">
                        <li class="Montserrat">
                          {{ langObj["Mission and Vision"] }}
                        </li>
                        <li class="Montserrat">
                          {{ langObj["Build your asset"] }}
                        </li>
                      </ul>
                      <span class="indicator"></span>
                    </div>
                    <div
                      class="tip-content"
                      :class="{ 'tip-active': tipActive === 1 }"
                      @mouseover="tipHandleMouseOver($event, 1)"
                      @mouseout="tipHandleMouseOut($event)"
                      @click="introFooterJumpClick('What we do')"
                    >
                      <p class="tip-header Montserrat">
                        {{ langObj["What we do?"] }}
                      </p>
                      <ul class="tip-list">
                        <li class="Montserrat">{{ langObj["Our Growth"] }}</li>
                        <li class="Montserrat">
                          {{ langObj["Our Partners"] }}
                        </li>
                      </ul>
                      <span class="indicator"></span>
                    </div>

                    <div
                      class="tip-content"
                      :class="{ 'tip-active': tipActive === 2 }"
                      @mouseover="tipHandleMouseOver($event, 2)"
                      @mouseout="tipHandleMouseOut($event)"
                      @click="introFooterJumpClick('Introduction')"
                    >
                      <p class="tip-header Montserrat">
                        {{ langObj["Introduction"] }}
                      </p>
                      <span class="indicator"></span>
                    </div>
                    <div
                      class="tip-content"
                      :class="{ 'tip-active': tipActive === 3 }"
                      @mouseover="tipHandleMouseOver($event, 3)"
                      @mouseout="tipHandleMouseOut($event)"
                      @click="introFooterJumpClick('How to get verified?')"
                    >
                      <p class="tip-header Montserrat">
                        {{ langObj["How to get verified?"] }}
                      </p>
                      <span class="indicator"></span>
                    </div>
                    <div
                      class="tip-content"
                      :class="{ 'tip-active': tipActive === 4 }"
                      @mouseover="tipHandleMouseOver($event, 4)"
                      @mouseout="tipHandleMouseOut($event)"
                      @click="introFooterJumpClick('Trusted by many')"
                    >
                      <p class="tip-header Montserrat">
                        {{ langObj["Trusted by many"] }}
                      </p>
                      <span class="indicator"></span>
                    </div>
                  </div>
                </div>
              </el-tooltip>
            </div>
            <div
              class="page-name"
              :class="{
                'intro-active': introActive === 1,
                introHover: introHover === 1,
              }"
              @mouseover="introHandleMouseOver($event, 1)"
              @mouseout="introHandleMouseOut($event)"
              @click="jumpActive(6)"
            >
              <!-- <el-tooltip
                popper-class="intro-tooltip"
                effect="light"
                placement="bottom"
              >
                <div> -->
              <span class="Montserrat">{{ langObj["Get Started"] }}</span>
              <!-- <ArrowBottom></ArrowBottom>
                </div> -->
              <!-- <div slot="content">
                  <div class="info-tip">
                  </div>
                </div> -->
              <!-- </el-tooltip> -->
            </div>
            <div
              class="page-name"
              :class="{
                'intro-active': introActive === 2,
                introHover: introHover === 2,
              }"
              @mouseover="introHandleMouseOver($event, 2)"
              @mouseout="introHandleMouseOut($event)"
              @click="introFooterJumpClick('Highlights')"
            >
              <el-tooltip
                popper-class="intro-tooltip"
                effect="light"
                placement="bottom"
              >
                <div>
                  <span class="Montserrat">{{
                    langObj["Crypto Academy"]
                  }}</span>
                  <ArrowBottom></ArrowBottom>
                </div>
                <div slot="content">
                  <div class="info-tip">
                    <div
                      class="tip-content"
                      :class="{ 'tip-active': tipActive === 5 }"
                      @mouseover="tipHandleMouseOver($event, 5)"
                      @mouseout="tipHandleMouseOut($event)"
                      @click="introFooterJumpClick('Highlights')"
                    >
                      <p class="tip-header Montserrat">
                        {{ langObj["Highlights"] }}
                      </p>
                      <span class="indicator"></span>
                    </div>
                    <div
                      class="tip-content"
                      :class="{ 'tip-active': tipActive === 6 }"
                      @mouseover="tipHandleMouseOver($event, 6)"
                      @mouseout="tipHandleMouseOut($event)"
                      @click="introFooterJumpClick('Novice')"
                    >
                      <p class="tip-header Montserrat">
                        {{ langObj["Novice"] }}
                      </p>
                      <span class="indicator"></span>
                    </div>
                    <div
                      class="tip-content"
                      :class="{ 'tip-active': tipActive === 7 }"
                      @mouseover="tipHandleMouseOver($event, 7)"
                      @mouseout="tipHandleMouseOut($event)"
                      @click="introFooterJumpClick('Advanced')"
                    >
                      <p class="tip-header Montserrat">
                        {{ langObj["Advanced"] }}
                      </p>
                      <span class="indicator"></span>
                    </div>
                  </div>
                </div>
              </el-tooltip>
            </div>
          </div>
          <div class="start-btn" @click="openSignUpDialog">
            <span>{{ langObj["注册"] }}</span>
          </div>
          <div class="switch-lang">
            <el-tooltip
              popper-class="intro-tooltip"
              effect="light"
              placement="bottom"
            >
              <div class="lang-wrapper">
                <GlobalSVG :width="36" :height="36"></GlobalSVG>
                <span class="lang-text">{{
                  $store.state.languageContent
                }}</span>
              </div>
              <div slot="content">
                <div class="info-tip">
                  <div
                    v-for="(item, index) in languageTypeData"
                    :key="item.LanguageType"
                    class="tip-content"
                    :class="{ 'tip-active': tipActive === index }"
                    @mouseover="langHandleMouseOver($event, index)"
                    @mouseout="langHandleMouseOut($event)"
                    @click="langClickHandle(item, index)"
                  >
                    <p class="tip-header Montserrat">
                      {{ item.LanguageContent }}
                    </p>
                    <span class="indicator"></span>
                  </div>
                </div>
              </div>
            </el-tooltip>
          </div>
          <div class="tokenport" @click="tokenportClick">
            <span>{{ langObj["Tokenport"] }}</span>
          </div>
          <div class="intro-download" @click="downloadClick">
            <img src="@/assets/images/index/intro-download.png" alt="" />
          </div>
        </div>
      </div>
    </div>

    <router-view></router-view>

    <div class="page-footer" v-if="!isShowIntro && showFooter">
      <div class="first-content">
        <div class="page-footer-content">
          <div class="logo-content">
            <img
              class="logo-img"
              src="../assets/images/common/logo.png"
              alt=""
              @click="jumpHomePage"
            />
            <span class="logo-link"
              >S6.com {{ langObj["数字货币私人银行"] }}</span
            >
            <img
              class="logo-jump"
              src="../assets/images/common/jump.png"
              alt=""
            />
          </div>
          <div class="tg-content">
            <img
              class="erweima"
              src="../assets/images/common/erweima.png"
              alt=""
            />
            <span class="join">{{ langObj["加入官方telegram频道"] }}</span>
          </div>
          <div class="text-content">
            <span class="sub-title">{{ langObj["关于"] }}</span>
            <span
              class="sub-txt"
              @click="introFooterJumpClick('Announcement-Center')"
              >{{ langObj["公告中心"] }}</span
            >
            <span class="sub-txt">{{ langObj["帮助中心"] }}</span>
          </div>
          <div class="text-content">
            <span class="sub-title">{{ langObj["条款"] }}</span>
            <span
              class="sub-txt"
              @click="introFooterJumpClick('Privacy Policy')"
              >{{ langObj["服务协议"] }}</span
            >
            <span
              class="sub-txt"
              @click="introFooterJumpClick('Privacy Policy')"
              >{{ langObj["隐私协议"] }}</span
            >
            <span class="sub-txt">{{ langObj["免责声明"] }}</span>
          </div>
          <div class="text-content">
            <span class="sub-title">{{ langObj["其他"] }}</span>
            <span class="sub-txt">{{ langObj["费率标准"] }}</span>
            <span class="sub-txt">{{ langObj["API"] }}</span>
          </div>
        </div>
      </div>
    </div>

    <!--介绍页底部栏 Mobile  -->
    <div v-if="$device.mobile" class="footer-wrapper-mobile">
      <div class="intro-footer-mobile">
        <div class="footer-content-mobile">
          <div class="content-left-mobile">
            <div class="footer-info-mobile">
              <p class="info-title-mobile">
                <span>{{ langObj["About Us"] }}</span>
              </p>
              <p>
                <span @click="introFooterJumpClickMobile('Who we are')">{{
                  langObj["Who we are"]
                }}</span>
              </p>
              <p>
                <span @click="introFooterJumpClickMobile('What we do')">{{
                  langObj["What we do"]
                }}</span>
              </p>
              <p>
                <span @click="introFooterJumpClickMobile('Our partners')">{{
                  langObj["Our partners"]
                }}</span>
              </p>
            </div>
            <div class="footer-info-mobile">
              <p class="info-title-mobile">
                <span>{{ langObj["Crypto Academy"] }}</span>
              </p>
              <p>
                <span @click="introFooterJumpClickMobile('Highlights')">{{
                  langObj["Highlights"]
                }}</span>
              </p>
              <p>
                <span @click="introFooterJumpClickMobile('Novice')">{{
                  langObj["Novice"]
                }}</span>
              </p>
              <p>
                <span @click="introFooterJumpClickMobile('Advanced')">{{
                  langObj["Advanced"]
                }}</span>
              </p>
            </div>
            <div class="footer-info-mobile">
              <p class="info-title-mobile">
                <span>{{ langObj["Support"] }}</span>
              </p>
              <p>
                <span @click="introFooterJumpClickMobile('FAQs')">{{
                  langObj["FAQs"]
                }}</span>
              </p>
              <p>
                <span @click="introFooterJumpClickMobile('How-to-guide')">{{
                  langObj["How-to-guide"]
                }}</span>
              </p>
              <p>
                <span @click="introFooterJumpClickMobile('Help Center')">{{
                  langObj["Help Center"]
                }}</span>
              </p>
            </div>
            <div class="footer-info-mobile">
              <p class="info-title-mobile"></p>
              <p>
                <span
                  @click="introFooterJumpClickMobile('Withdrawal Issues')"
                  >{{ langObj["Withdrawal Issues"] }}</span
                >
              </p>
              <p>
                <span @click="introFooterJumpClickMobile('Deposit Issues')">{{
                  langObj["Deposit Issues"]
                }}</span>
              </p>
              <p>
                <span @click="introFooterJumpClickMobile('Common Issues')">{{
                  langObj["Common Issues"]
                }}</span>
              </p>
            </div>
            <div class="footer-info-mobile">
              <p class="info-title-mobile">
                <span>{{ langObj["Legal"] }}</span>
              </p>
              <p>
                <span @click="introFooterJumpClickMobile('User Agreement')">{{
                  langObj["User Agreement"]
                }}</span>
              </p>
              <p>
                <span @click="introFooterJumpClickMobile('Privacy Policy')">{{
                  langObj["Privacy Policy"]
                }}</span>
              </p>
              <p>
                <span
                  @click="introFooterJumpClickMobile('Operational Risks')"
                  >{{ langObj["Operational Risks"] }}</span
                >
              </p>
            </div>
          </div>
          <div class="content-right-mobile">
            <div class="footer-logo-mobile">
              <IntroLogo width="2.42rem" height=".57rem"></IntroLogo>
            </div>
            <p class="footer-tip-mobile Montserrat">
              {{ langObj["Redefining your trading experience!"] }}
            </p>
          </div>
        </div>
        <div class="footer-copyright-mobile">
          <p class="copyright-text-mobile Montserrat">
            {{ langObj["Copyright © 2023 S6.com. All Rights Reserved."] }}
          </p>
        </div>
      </div>
    </div>

    <!-- 介绍页底部栏 PC -->
    <div v-else-if="isShowIntro" class="footer-wrapper">
      <div class="intro-footer">
        <div class="footer-content">
          <div class="content-left">
            <div class="footer-info">
              <p class="info-title">{{ langObj["About Us"] }}</p>
              <p @click="introFooterJumpClick('Who we are')">
                {{ langObj["Who we are"] }}
              </p>
              <p @click="introFooterJumpClick('What we do')">
                {{ langObj["What we do"] }}
              </p>
              <p @click="introFooterJumpClick('Our partners')">
                {{ langObj["Our partners"] }}
              </p>
            </div>
            <div class="footer-info">
              <p class="info-title">{{ langObj["Crypto Academy"] }}</p>
              <p @click="introFooterJumpClick('Highlights')">
                {{ langObj["Highlights"] }}
              </p>
              <p @click="introFooterJumpClick('Novice')">
                {{ langObj["Novice"] }}
              </p>
              <p @click="introFooterJumpClick('Advanced')">
                {{ langObj["Advanced"] }}
              </p>
            </div>
            <div class="footer-info">
              <p class="info-title">{{ langObj["Support"] }}</p>
              <p @click="introFooterJumpClick('FAQs')">{{ langObj["FAQs"] }}</p>
              <p @click="introFooterJumpClick('How-to-guide')">
                {{ langObj["How-to-guide"] }}
              </p>
              <p @click="introFooterJumpClick('Help Center')">
                {{ langObj["Help Center"] }}
              </p>
            </div>
            <div class="footer-info">
              <p class="info-title"></p>
              <p @click="introFooterJumpClick('Withdrawal Issues')">
                {{ langObj["Withdrawal Issues"] }}
              </p>
              <p @click="introFooterJumpClick('Deposit Issues')">
                {{ langObj["Deposit Issues"] }}
              </p>
              <p @click="introFooterJumpClick('Common Issues')">
                {{ langObj["Common Issues"] }}
              </p>
            </div>
            <div class="footer-info">
              <p class="info-title">{{ langObj["Legal"] }}</p>
              <p @click="introFooterJumpClick('User Agreement')">
                {{ langObj["User Agreement"] }}
              </p>
              <p @click="introFooterJumpClick('Privacy Policy')">
                {{ langObj["Privacy Policy"] }}
              </p>
              <p @click="introFooterJumpClick('Operational Risks')">
                {{ langObj["Operational Risks"] }}
              </p>
            </div>
          </div>
          <div class="content-right">
            <div class="footer-logo">
              <IntroLogo width="153" height="43"></IntroLogo>
            </div>
            <p class="footer-tip Montserrat">
              {{ langObj["Redefining your trading experience!"] }}
            </p>
          </div>
        </div>
        <div class="footer-copyright">
          <p class="copyright-text Montserrat">
            {{ langObj["Copyright © 2023 S6.com. All Rights Reserved."] }}
          </p>
        </div>
      </div>
    </div>

    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      width="780px"
      :before-close="handleClose"
    >
      <div class="lang-box-pop" v-loading="langLoading">
        <div
          class="lang-box-flex"
          v-for="(item, index) in langArr"
          :key="index"
          @click="langChoose(item)"
        >
          <img class="lang-logo" :src="item.LanguageFace" alt="" />
          <span class="lang-span">{{ item.LanguageContent }}</span>
        </div>
      </div>
    </el-dialog>

    <!-- 介绍页顶部栏点击更多弹窗 Mobile  -->
    <el-drawer
      :visible.sync="isShowMore"
      direction="rtl"
      close-on-press-escape
      :modal-append-to-body="false"
      size="3rem"
      class="drawerBox"
    >
      <div class="threeBox">
        <div
          class="three-item"
          @click="threeClick('About us')"
          :expanded="aboutUsExpanded"
        >
          <div class="item-parent">
            <div
              :class="['item-left', { 'item-left-expanded': aboutUsExpanded }]"
            >
              {{ langObj["About us"] }}
            </div>
            <div
              :class="[
                'item-right',
                { 'item-right-expanded': aboutUsExpanded },
              ]"
            ></div>
          </div>
          <div class="item-children" v-show="aboutUsExpanded">
            <div
              class="item-children-item"
              @click.stop="introFooterJumpClickMobile('Who we are')"
            >
              <div class="item-children-title">
                {{ langObj["Who are we?"] }}
              </div>
              <div class="item-children-text">
                {{ langObj["Mission and Vision"] }}
              </div>
              <div class="item-children-text">
                {{ langObj["Build your asset"] }}
              </div>
            </div>

            <div
              class="item-children-item"
              style="margin-top: 0.15rem"
              @click.stop="introFooterJumpClickMobile('What we do')"
            >
              <div class="item-children-title">
                {{ langObj["What we do?"] }}
              </div>
              <div class="item-children-text">
                {{ langObj["Our Growth"] }}
              </div>
              <div class="item-children-text">
                {{ langObj["Our Partners"] }}
              </div>
            </div>

            <div
              class="item-children-item"
              @click.stop="introFooterJumpClickMobile('Introduction')"
            >
              <span class="item-children-title" style="margin-top: 0.12rem">{{
                langObj["Introduction"]
              }}</span>
            </div>
            <div
              class="item-children-item"
              @click.stop="introFooterJumpClickMobile('How to get verified?')"
            >
              <span class="item-children-title" style="margin-top: 0.12rem">{{
                langObj["How to get verified?"]
              }}</span>
            </div>
            <div
              class="item-children-item"
              @click.stop="introFooterJumpClickMobile('Trusted by many')"
            >
              <span
                class="item-children-title"
                style="margin-top: 0.12rem; padding-bottom: 0.2rem"
                >{{ langObj["Trusted by many"] }}</span
              >
            </div>
          </div>
        </div>

        <!-- <div
          class="three-item"
          @click="threeClick('Get Started')"
          :expanded="getStartedExpanded"
        >
          <div class="item-parent">
            <div
              :class="[
                'item-left',
                { 'item-left-expanded': getStartedExpanded },
              ]"
            >
              {{ langObj["Get Started"] }}
            </div>
            <div
              :class="[
                'item-right',
                { 'item-right-expanded': getStartedExpanded },
              ]"
            ></div>
          </div>
          <div class="item-children" v-show="getStartedExpanded">
          </div>
        </div> -->
        <div
          class="three-item"
          @click="threeClick('Crypto Academy')"
          :expanded="cryptoAcademyExpanded"
        >
          <div class="item-parent">
            <div
              :class="[
                'item-left',
                { 'item-left-expanded': cryptoAcademyExpanded },
              ]"
            >
              {{ langObj["Crypto Academy"] }}
            </div>
            <div
              :class="[
                'item-right',
                { 'item-right-expanded': cryptoAcademyExpanded },
              ]"
            ></div>
          </div>
          <div class="item-children" v-show="cryptoAcademyExpanded">
            <div
              class="item-children-item"
              @click.stop="introFooterJumpClickMobile('Highlights')"
            >
              <span class="item-children-item-btn">{{
                langObj["Highlights"]
              }}</span>
            </div>

            <div
              class="item-children-item"
              @click.stop="introFooterJumpClickMobile('Novice')"
            >
              <span class="item-children-item-btn">{{
                langObj["Novice"]
              }}</span>
            </div>

            <div
              class="item-children-item"
              @click.stop="introFooterJumpClickMobile('Advanced')"
            >
              <span class="item-children-item-btn">{{
                langObj["Advanced"]
              }}</span>
            </div>
          </div>
        </div>

        <div class="three-item" @click="threeClick('Tokenport')">
          <div class="item-parent">
            <div :class="['item-left']">
              {{ langObj["Tokenport"] }}
            </div>
          </div>
        </div>
      </div>

      <div class="drawer-btn sign-up" @click="openSignUpDialogMobile">
        {{ langObj["注册"] }}
      </div>
      <div class="drawer-btn" @click="openAppMobile">
        {{ langObj["Start Trading"] }}
      </div>
      <!-- <div class="drawer-bottom-btn">
        <div class="btn-login" @click="openAppMobile">
          {{ langObj["Login"] }}
        </div>
        <div class="btn-sign-up" @click="openSignUpDialogMobile">
          {{ langObj["注册"] }}
        </div>
      </div> -->
    </el-drawer>

    <!-- 介绍页注册弹窗Mobile -->
    <div
      v-show="isShowSignUpDialog"
      class="intro-dialog-wrapper-mobile"
      @click.stop="closeSignUpDialog"
      v-if="$device.mobile"
    >
      <div class="intro-dialog-content-mobile" @click.stop>
        <div class="content-right-mobile">
          <p class="right-title-mobile">{{ langObj["Create Account"] }}</p>
          <div class="account-imgs-mobile">
            <div
              class="account-img-mobile"
              :class="{ 'account-active-mobile': registerType === 1 }"
              @click="registerType = 1"
            >
              <SignUpAccount width=".26rem" height=".28rem"></SignUpAccount>
            </div>
            <div
              class="email-img-mobile"
              :class="{ 'account-active-mobile': registerType === 3 }"
              @click="registerType = 3"
            >
              <SignUpEmail width=".26rem" height=".20rem"></SignUpEmail>
            </div>
          </div>
          <div class="sign-up-input-mobile">
            <el-form
              :model="accountInfoData"
              :rules="accountRules"
              ref="signUpFormRef"
              @submit.native.prevent
            >
              <el-form-item prop="registerName">
                <el-input
                  v-model="accountInfoData.registerName"
                  :placeholder="
                    registerType === 1
                      ? langObj['Username']
                      : langObj['Email Address']
                  "
                />
              </el-form-item>
              <el-form-item prop="pwd">
                <el-input
                  v-model="accountInfoData.pwd"
                  :placeholder="langObj['Password']"
                  maxlength="20"
                  show-password
                />
              </el-form-item>
              <el-form-item prop="confirmPwd">
                <el-input
                  v-model="accountInfoData.confirmPwd"
                  :placeholder="langObj['Confirm Password']"
                  maxlength="20"
                  show-password
                />
              </el-form-item>
              <el-form-item>
                <el-input
                  v-model="accountInfoData.inviteCode"
                  :placeholder="langObj['Invitation Code']"
                  maxlength="20"
                />
              </el-form-item>
            </el-form>
          </div>
          <div class="btn">
            <div
              class="sign-up-btn-mobile"
              @click="signUpClickHandle('signUpFormRef')"
            >
              <span class="Montserrat">{{ langObj["注册"] }}</span>
            </div>
            <div class="login-btn-mobile" @click="openAppMobile">
              <span class="Montserrat">{{ langObj["Login"] }}</span>
            </div>
          </div>
        </div>
        <div class="close-btn-mobile" @click="closeSignUpDialog">
          <img
            class="close-img-mobile"
            src="@/assets/images/intro/sign-up-close.png"
            alt=""
          />
        </div>
      </div>
    </div>

    <!-- 介绍页注册弹窗PC -->
    <div
      v-show="isShowSignUpDialog"
      class="intro-dialog-wrapper"
      @click.stop="closeSignUpDialog"
      v-else
    >
      <div class="intro-dialog-content" @click.stop>
        <div class="content-left">
          <div class="sign-up-bg-wrapper">
            <img
              class="sign-up-bg-img"
              src="@/assets/images/intro/sign-up-bg.png"
              alt=""
            />
          </div>
          <div class="left-info">
            <div class="info-logo">
              <img
                class="logo-img"
                src="@/assets/images/intro/sign-up-logo.png"
                alt=""
              />
            </div>
            <p class="info-title Montserrat">{{ langObj["Welcome Back!"] }}</p>
            <p class="info-text Montserrat">
              {{
                langObj[
                  "To keep connected with us please login with your account"
                ]
              }}
            </p>
            <div class="sign-in-btn" @click="introJumpOtherPageClick(6)">
              <span class="Montserrat">{{ langObj["Login"] }}</span>
            </div>
          </div>
        </div>
        <div class="content-right">
          <p class="right-title">{{ langObj["Create Account"] }}</p>
          <div class="account-imgs">
            <div
              class="account-img"
              :class="{ 'account-active': registerType === 1 }"
              @click="registerType = 1"
            >
              <SignUpAccount></SignUpAccount>
            </div>
            <div
              class="email-img"
              :class="{ 'account-active': registerType === 3 }"
              @click="registerType = 3"
            >
              <SignUpEmail></SignUpEmail>
            </div>
          </div>
          <div class="sign-up-input">
            <el-form
              :model="accountInfoData"
              :rules="accountRules"
              ref="signUpFormRef"
              @submit.native.prevent
            >
              <el-form-item prop="registerName">
                <el-input
                  v-model="accountInfoData.registerName"
                  :placeholder="
                    registerType === 1
                      ? langObj['Username']
                      : langObj['Email Address']
                  "
                />
              </el-form-item>
              <el-form-item prop="pwd">
                <el-input
                  v-model="accountInfoData.pwd"
                  :placeholder="langObj['Password']"
                  maxlength="20"
                  show-password
                />
              </el-form-item>
              <el-form-item prop="confirmPwd">
                <el-input
                  v-model="accountInfoData.confirmPwd"
                  :placeholder="langObj['Confirm Password']"
                  maxlength="20"
                  show-password
                />
              </el-form-item>
              <el-form-item>
                <el-input
                  v-model="accountInfoData.inviteCode"
                  :placeholder="langObj['Invitation Code']"
                  maxlength="20"
                />
              </el-form-item>
            </el-form>
          </div>
          <div class="sign-up-btn" @click="signUpClickHandle('signUpFormRef')">
            <span class="Montserrat">{{ langObj["注册"] }}</span>
          </div>
        </div>
        <div class="close-btn" @click="closeSignUpDialog">
          <img
            class="close-img"
            src="@/assets/images/intro/sign-up-close.png"
            alt=""
          />
        </div>
      </div>
    </div>

    <!-- 滑动弹窗 Mobile -->
    <div
      v-show="isDialogOpen && $device.mobile"
      class="intro-dialog-wrapper-mobile"
      @click.stop="closeScrollDialog"
    >
      <div class="dialogBox-mobile" @click.stop>
        <div class="close-btn-mobile" @click="closeScrollDialog">
          <img
            class="close-img-mobile"
            src="@/assets/images/intro/sign-up-close.png"
            alt=""
          />
        </div>
        <div class="dialogTitle-mobile Montserrat">
          {{ langObj["Redefining"] }}
          <span class="dialogTitleColo-mobile Montserrat">
            {{ langObj["Your Trading"] }} {{ langObj["Experience"] }}</span
          >
        </div>
        <div class="dialogContent-mobile Montserrat">
          {{ langObj["With over 200 Trading Pairs in 100+ countries!"] }}
        </div>
        <div class="dialogImg-mobile">
          <img src="@/assets/images/intro/commonMobile/rocket@2x.png" />
        </div>
        <div class="markedWords-mobile Montserrat">
          {{ langObj["Subscribe to our newsletter:"] }}
        </div>
        <div class="bottom-mobile">
          <div class="left-mobile">
            <el-input
              v-model="emailValue"
              :placeholder="langObj['Email']"
              class="dialogInput-mobile"
            ></el-input>
          </div>

          <div class="right-mobile">
            <el-button
              class="button-mobile Montserrat"
              @click="subscribeClickHandle"
              >{{ langObj["Subscribe"] }}</el-button
            >
          </div>
        </div>
      </div>
    </div>

    <!-- 滑动弹窗PC -->
    <div
      v-show="isDialogOpen && !$device.mobile"
      class="intro-dialog-wrapper"
      @click.stop="closeScrollDialog"
    >
      <div class="dialogBox" @click.stop>
        <div class="top-img">
          <img class="plane-img" src="@/assets/images/intro/plane.png" alt="" />
        </div>
        <div class="close-btn" @click="closeScrollDialog">
          <img
            class="close-img"
            src="@/assets/images/intro/sign-up-close.png"
            alt=""
          />
        </div>
        <div class="dialogTitle Montserrat">
          {{ langObj["Redefining"] }}
          <span class="dialogTitleColo Montserrat"
            >{{ langObj["Your Trading"] }} <br />{{
              langObj["Experience"]
            }}</span
          >
        </div>
        <div class="dialogContent Montserrat">
          {{ langObj["With over 200 Trading Pairs in 100+ countries!"] }}
        </div>
        <div class="dialogImg">
          <img src="@/assets/images/intro/aboutUs/AboutGroup1891.png" />
        </div>
        <div class="markedWords Montserrat">
          {{ langObj["Subscribe to our newsletter:"] }}
        </div>
        <div class="bottom">
          <div class="left">
            <el-input
              v-model="emailValue"
              :placeholder="langObj['Email']"
              class="dialogInput"
            ></el-input>
          </div>

          <div class="right">
            <el-button
              class="button Montserrat"
              @click="subscribeClickHandle"
              >{{ langObj["Subscribe"] }}</el-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import QRCode from "qrcodejs2-fix";
import axios from "axios";

import locale from "element-ui/lib/locale";
import zh_CN from "element-ui/lib/locale/lang/zh-CN";
import en from "element-ui/lib/locale/lang/en";

import GlobalSVG from "./svg/Global";
import ArrowBottom from "./svg/ArrowBottom";
import IntroLogo from "./svg/IntroLogo";
import SignUpAccount from "./svg/SignUpAccount";
import SignUpEmail from "./svg/SignUpEmail";

export default {
  name: "HelloWorld",
  components: {
    GlobalSVG,
    ArrowBottom,
    IntroLogo,
    SignUpAccount,
    SignUpEmail,
  },
  props: {
    msg: String,
  },
  data () {
    const checkName = (rule, value, callback) => {
      if (value === "") {
        callback(
          new Error(
            this.registerType === 1
              ? this.langObj["请输入用户名"]
              : this.langObj["请输入邮箱"]
          )
        );
      } else {
        callback();
      }
    };
    const validatePwd = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.langObj["请输入密码"]));
      } else if (value?.length < 6) {
        callback(new Error(this.langObj["密码长度为 6-20 位字符"]));
      } else if (
        !/(?!^(\d+|[a-zA-Z]+|[~!@#$%^&*()_.]+)$)^[\w~!@#$%^&*()_.]{6,20}$/.test(
          value
        )
      ) {
        callback(
          new Error(this.langObj["密码不能为纯英文或数字，不能包含空格"])
        );
      } else {
        callback();
      }
    };
    const validateConfirmPwd = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.langObj["请输入确认密码"]));
      } else if (value !== this.accountInfoData.confirmPwd) {
        callback(new Error(this.langObj["两次输入密码不一致!"]));
      } else {
        callback();
      }
    };
    return {
      //Mobile状态
      isShowMore: false,
      aboutUsExpanded: false,
      getStartedExpanded: false,
      cryptoAcademyExpanded: false,
      isShowSignUpDialogMobile: false,
      isShowLang: false,
      langPop: false,

      //PC状态
      active: sessionStorage.getItem("active")
        ? sessionStorage.getItem("active")
        : 0,
      // 是否登录
      login: true,
      // 用户中心弹窗
      userFlag: false,
      // 国际币种弹窗
      langCoinFlag: false,
      // 是否看到公共页面顶部
      showHeader: false,
      // 是否看到公共页面底部
      showFooter: false,
      // 用户中心当前选中的
      activeA: "",
      // 所有币种
      internationalCurrencyArr: [],
      erweima: "",
      dialogVisible: false,
      langArr: [],
      langLoading: false,
      // 介绍页面
      isShowIntro: true,
      isIntro: false,
      introActive: 0,
      introHover: -1,
      isTop: true,
      switchIntroTopScrollFn: () => { },
      tipActive: -1,
      languageTypeData: [],
      langActive: -1,
      // 注册弹窗
      isShowSignUpDialog: false,
      registerType: 1,
      accountInfoData: {
        registerName: "",
        pwd: "",
        confirmPwd: "",
        inviteCode: "",
      },
      accountRules: {
        registerName: [{ validator: checkName, trigger: "blur" }],
        pwd: [{ validator: validatePwd, trigger: "blur" }],
        confirmPwd: [{ validator: validateConfirmPwd, trigger: "blur" }],
      },
      isDialogOpen: false,
      emailValue: "",
      dialogScrollFn: () => { },
      dialogTimer: null,
      isShowScrollDialog: false,
      initEmailSubscribeUrl: "",
    };
  },
  watch: {
    $route: {
      handler: function (to) {
        if (to.query.ref) {
          window.sessionStorage.setItem('inviteCodeRef', to.query.ref)
        }
        if (
          to.path === "/Login" ||
          to.path === "/SecondaryVerification" ||
          to.path === "/forgetPwd" ||
          to.path === "/register" ||
          to.path === "/"
        ) {
          this.showFooter = false;
        } else {
          this.showFooter = true;
        }
        if (to.path.includes("/HomePage")) {
          this.active = 0;
        }
        if (to.path.includes("/Market")) {
          this.active = 1;
        }
        if (to.path.includes("/CoinCurrencyTrading")) {
          this.active = 2;
        }
        if (to.path.includes("/UStandardContract")) {
          this.active = 3;
        }
        if (to.path.includes("/CurrencyStandardContract")) {
          this.active = 4;
        }

        // 介绍页
        if (
          to.path.toLocaleUpperCase() === "/ABOUTUS" ||
          to.path.toLocaleUpperCase() === "/GETSTARTED" ||
          to.path.toLocaleUpperCase().includes("/CRYPTOACADEMY")
        ) {
          this.isShowIntro = true;
          this.showFooter = false;
          this.switchIntroTopScrollFn = () => {
            if (window.scrollY === 0) {
              this.isTop = true
            } else {
              if (this.isTop) this.isTop = false
            }
          }
          window.addEventListener("scroll", this.switchIntroTopScrollFn);

          // if (!this.isShowScrollDialog) {
          //   this.isShowScrollDialog = true;
          //   this.dialogScrollFn = () => {
          //     this.dialogTimer = setTimeout(() => {
          //       this.isDialogOpen = true;
          //       clearTimeout(this.dialogTimer);
          //     }, 5000);
          //     window.removeEventListener("scroll", this.dialogScrollFn);
          //   };
          //   window.addEventListener("scroll", this.dialogScrollFn);
          // }

          this.getLanguageTypeData();

          if (to.path.toLocaleUpperCase() === "/ABOUTUS") {
            this.introActive = 0;
          } else if (to.path.toLocaleUpperCase() === "/GETSTARTED") {
            this.introActive = 0;
          } else if (to.path.toLocaleUpperCase() === "/CRYPTOACADEMY") {
            this.introActive = 2;
          } else {
            this.introActive = -1;
          }
        } else {
          if (to.path !== "/") {
            this.showHeader = true
          }
          this.closeScrollDialog();
          if (this.isShowIntro) this.isShowIntro = false;
          window.removeEventListener("scroll", this.switchIntroTopScrollFn);

          if (this.dialogTimer) clearTimeout(this.dialogTimer);
        }
      },
      immediate: true,
    },
    isShowSignUpDialog (newValue) {
      if (newValue) {
        document.body.style.overflow = "hidden";
        this.accountInfoData.inviteCode = window.sessionStorage.getItem('inviteCodeRef') ?? ''
      } else {
        document.body.style.overflow = "auto";
      }
    },
    isDialogOpen (newValue) {
      if (newValue) {
        document.body.style.overflow = "hidden";
        this.langPop = false
      } else {
        document.body.style.overflow = "auto";
      }
    },
    langPop (newValue) {
      if (newValue) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    },
    isShowMore (newValue) {
      if (newValue) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    },
    registerType () {
      this.$refs["signUpFormRef"].resetFields();
      // this.accountInfoData.inviteCode = "";
    },
    "$store.getters.getIsShowIntroSignUpData" (newValue) {
      if (newValue) {
        this.isShowSignUpDialog = true;
        this.$store.dispatch("getIntroSignUpAction", {
          isShowIntroSignUp: false,
        });
      }
    }
  },
  created () {
    const isQQBrowser = navigator.userAgent.includes('MQQBrowser')
    if (isQQBrowser) {
      window.addEventListener('pageshow', () => {
        if (window.name !== "hasLoad" && navigator.userAgent.includes('MQQBrowser')) {
          location.reload();
          window.name = "hasLoad";
        } else {
          window.name = "";
        }
      })
      return
    }
    this.getLang();
    this.queryIsLogin();
    this.queryInternationalCurrency();
  },
  mounted () {
    // 生成二维码
    this.crateQrcode();
  },
  updated () { },

  computed: {
    ...mapState({
      isLogin: (state) => state.isLogin,
      // 当前币种
      internationalCurrency: (state) => state.internationalCurrency,
      // 当前币种费率
      internationalCurrencyRate: (state) => state.internationalCurrencyRate,
      // 当前币种符号
      internationalCurrencySymbol: (state) => state.internationalCurrencySymbol,
      // 当前语言环境
      languageType: (state) => state.languageType
    }),
    thirdLanguageType () {
      return this.languageType === 'zh_CN' ? 'zh-cn' : 'en-001'
    },
    langObj: {
      get () {
        return this.$store.getters.langObjData;
      },
      set () { },
    },
  },
  methods: {
    //Mobile 方法
    //点击展开更多菜单
    showMore () {
      this.isShowMore = true;
      // document.body.style.overflow = "hidden";
    },
    // 更多菜单中点击事件
    threeClick (value) {
      switch (value) {
        case "About us":
          this.aboutUsExpanded = !this.aboutUsExpanded;
          this.getStartedExpanded = false;
          this.cryptoAcademyExpanded = false;
          break;
        case "Get Started":
          this.getStartedExpanded = !this.getStartedExpanded;
          this.aboutUsExpanded = false;
          this.cryptoAcademyExpanded = false;

          break;
        case "Crypto Academy":
          this.cryptoAcademyExpanded = !this.cryptoAcademyExpanded;
          this.aboutUsExpanded = false;
          this.getStartedExpanded = false;
          break;
        case "Tokenport":
          window.open(
            "https://tokenport.io/"
          );
          break;
        default:
          return "";
      }
    },

    langhandle () {
      this.langPop = true;
    },

    // 选择语言点击
    langClickHandleMobile (item, value) {
      this.$store.commit("changeLanguageType", item.LanguageType);
      axios.defaults.headers.common["lang"] = item.LanguageType;
      this.$store.commit("changeLanguageContent", item.LanguageContent);
      this.$store.commit("changeLanguageFace", item.LanguageFace);
      this.getLang();
      this.tipActive = value;
      this.langPop = false;
      // document.body.style.overflow = "auto";
    },

    // Mobile跳转页面方法
    introFooterJumpClickMobile (value) {
      this.closeSignUpDialog();
      this.closeScrollDialog();
      const AboutUs = "AboutUs",
        GetStarted = "GetStarted",
        CryptoAcademy = "CryptoAcademy";
      switch (value) {
        case "Who we are":
          if (this.$route.name === AboutUs) {
            this.$store.dispatch("getIntroPagePathAction", { path: value });
          } else {
            this.$router.push({ name: AboutUs, params: { path: value } });
          }
          this.isShowMore = false;
          break;
        case "What we do":
          if (this.$route.name === AboutUs) {
            this.$store.dispatch("getIntroPagePathAction", { path: value });
          } else {
            this.$router.push({ name: AboutUs, params: { path: value } });
          }
          this.isShowMore = false;

          break;
        case "Our partners":
          if (this.$route.name === AboutUs) {
            this.$store.dispatch("getIntroPagePathAction", { path: value });
          } else {
            this.$router.push({ name: AboutUs, params: { path: value } });
          }
          this.isShowMore = false;
          break;
        case "Introduction":
          if (this.$route.name === GetStarted) {
            this.$store.dispatch("getIntroPagePathAction", { path: value });
          } else {
            this.$router.push({ name: GetStarted, params: { path: value } });
          }
          this.isShowMore = false;
          break;
        case "How to get verified?":
          if (this.$route.name === GetStarted) {
            this.$store.dispatch("getIntroPagePathAction", { path: value });
          } else {
            this.$router.push({ name: GetStarted, params: { path: value } });
          }
          this.isShowMore = false;
          break;
        case "Trusted by many":
          if (this.$route.name === GetStarted) {
            this.$store.dispatch("getIntroPagePathAction", { path: value });
          } else {
            this.$router.push({ name: GetStarted, params: { path: value } });
          }
          this.isShowMore = false;
          break;
        case "Highlights":
          if (this.$route.name === CryptoAcademy) {
            this.$store.dispatch("getIntroPagePathAction", { path: value });
          } else {
            this.$router.push({ name: CryptoAcademy, params: { path: value } });
          }
          this.isShowMore = false;
          break;
        case "Novice":
          if (this.$route.name === CryptoAcademy) {
            this.$store.dispatch("getIntroPagePathAction", { path: value });
          } else {
            this.$router.push({ name: CryptoAcademy, params: { path: value } });
          }
          this.isShowMore = false;
          break;
        case "Advanced":
          if (this.$route.name === CryptoAcademy) {
            this.$store.dispatch("getIntroPagePathAction", { path: value });
          } else {
            this.$router.push({ name: CryptoAcademy, params: { path: value } });
          }
          this.isShowMore = false;
          break;
        case "FAQs":
          window.open(
            `https://s6globalex.zendesk.com/hc/${this.thirdLanguageType}/categories/23956752663321-Common-problem`
          );
          break;
        case "How-to-guide":
          window.open(
            `https://s6globalex.zendesk.com/hc/${this.thirdLanguageType}`
          );
          break;
        case "Help Center":
          window.open(`https://s6globalex.zendesk.com/hc/${this.thirdLanguageType}/sections/23956752709529-Deposit-Receipt-Withdraw-Transfer`);
          break;
        case "Withdrawal Issues":
          window.open(
            `https://s6globalex.zendesk.com/hc/${this.thirdLanguageType}/articles/24669902466201--How-to-withdraw-transfer-digital-currency-APP-side-`
          );
          break;
        case "Deposit Issues":
          window.open(
            `https://s6globalex.zendesk.com/hc/${this.thirdLanguageType}/articles/23956752712729-How-to-Deposit-receive-digital-currency`
          );
          break;
        case "Common Issues":
          window.open(
            `https://s6globalex.zendesk.com/hc/${this.thirdLanguageType}/categories/23956752663321-Common-problem`
          );
          break;
        case "User Agreement":
          window.open(
            `https://s6globalex.zendesk.com/hc/${this.thirdLanguageType}/articles/24921536159129-User-Agreement-and-Privacy-Policy`
          );
          break;
        case "Privacy Policy":
          window.open(
            `https://s6globalex.zendesk.com/hc/${this.thirdLanguageType}/articles/24921536159129-User-Agreement-and-Privacy-Policy`
          );
          break;
        case "Operational Risks":
          window.open(
            `https://s6globalex.zendesk.com/hc/${this.thirdLanguageType}`
          );
          break;
      }
    },
    // Mobile点击打开登录注册弹窗
    openSignUpDialogMobile () {
      this.isShowMore = false;
      this.$nextTick(() => {
        this.isShowSignUpDialog = true;
      })
    },
    // h5唤起APP
    openAppMobile () {
      const startTime = new Date()  //记录初始时间
      const t = 500
      const ifr = document.createElement('iframe')
      ifr.onload = () => {
        ifr.style.display = 'none'
        setTimeout(function () {
          document.body.removeChild(ifr)
          const endTime = new Date()  //记录结束时间
          if (endTime - startTime <= (t + 30)) {
            //两者之差小于30ms时跳转到下载页
            if (navigator.userAgent.includes('iPhone')) {
              window.location.href = 'https://j33wj.com/lsEIv'
            } else {
              window.location.href = 'https://play.google.com/store/apps/details?id=com.yy.s6&hl=zh-cn'
            }
            // window.location.href = 'https://play.google.com/store/apps/details?id=com.yy.s6&hl=zh-cn'
          }
        }, t)
      }
      ifr.src = 'myapp://example'  //打开app
      document.body.appendChild(ifr)
      ifr.onload()
    },

    //PC方法
    // 生成二维码
    crateQrcode () {
      if (
        process.env.VUE_APP_ENV === "dev" ||
        process.env.VUE_APP_ENV === "test"
      ) {
        this.erweima = "https://s6-test.yqc01.cn/s6/h5/DownLoad";
      } else if (process.env.VUE_APP_ENV === "prod") {
        // 正式环境
        this.erweima = "https://s6.yqc01.cn/s6/h5/DownLoad";
      } else if (process.env.VUE_APP_ENV === "build") {
        // 正式环境
        this.erweima = "https://h5.s6we.com/s6/h5/DownLoad";
      }
      // this.erweima = "https://play.google.com/store/apps/details?id=com.yy.s6&hl=zh-cn";
      new QRCode(this.$refs.qrcode, {
        text: this.erweima,
        width: 72,
        height: 72,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H,
      });
    },
    // 点击行情、币币交易等跳转
    jumpActive (index) {
      this.active = index;
      sessionStorage.setItem("active", this.active);
      if (index === 0) {
        this.$router.push({
          path: "/HomePage",
        });
      } else if (index === 1) {
        this.$router.push({
          path: "/Market",
        });
      } else if (index === 2) {
        this.$router.push({
          path: "/CoinCurrencyTrading",
        });
      } else if (index === 3) {
        this.$router.push({
          path: "/UStandardContract",
        });
      } else if (index === 4) {
        this.$router.push({
          path: "/CurrencyStandardContract",
        });
      } else if (index === 5) {
        this.$router.push({
          path: "/FinancialServices",
        });
      } else if (index === 6) {
        this.$router.push({
          path: "/Login",
        });
      }
    },
    // 点击用户中心
    jumpPage (index) {
      if (index === "geren") {
        this.$router.push({
          path: "/pageUCenter/personalOverview",
        });
      } else if (index === "zichan") {
        this.$router.push({
          path: "/pageUCenter/AssetManagement",
        });
      } else if (index === "trade") {
        this.$router.push({
          path: "/pageUCenter/TransactionManagement",
        });
      } else if (index === "licai") {
        this.$router.push({
          path: "/pageUCenter/FinancialManagement",
        });
      } else if (index === "tuiguang") {
        this.$router.push({
          path: "/pageUCenter/PromotionCenter",
        });
      } else if (index === "kaquan") {
        this.$router.push({
          path: "/pageUCenter/CardCenter",
        });
      } else if (index === "zhangdan") {
        this.$router.push({
          path: "/pageUCenter/BillManagement",
        });
      } else if (index === "out") {
        const data = {};
        this.http(this.ApiSetting.logout, data, {
          Authorization: sessionStorage.getItem("sessionID"),
        }).then(
          (res) => {
            if (res.data.statusCode === "000000") {
              sessionStorage.clear();
              this.queryIsLogin();
              this.$router.push({
                path: "/HomePage",
              });
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
      this.userFlag = false;
      this.active = "";
    },
    // 鼠标移入用户中心
    mouseOver () {
      this.userFlag = true;
      var str = location.href;
      var url = str.substring(str.lastIndexOf("/") + 1, str.length);
      if (url.startsWith("personalOverview")) {
        this.activeA = "geren";
      } else if (url.startsWith("AssetManagement")) {
        this.activeA = "zichan";
      } else if (url.startsWith("TransactionManagement")) {
        this.activeA = "trade";
      } else if (url.startsWith("FinancialManagement")) {
        this.activeA = "licai";
      } else if (url.startsWith("PromotionCenter")) {
        this.activeA = "tuiguang";
      } else if (url.startsWith("CardCenter")) {
        this.activeA = "kaquan";
      } else if (url.startsWith("BillManagement")) {
        this.activeA = "zhangdan";
      } else {
        this.activeA = "";
      }
    },
    // 鼠标离开用户中心
    mouseLeave () {
      this.userFlag = false;
    },
    // 点击国际币种
    langCoinClick () {
      this.langCoinFlag = true;
    },
    // 鼠标移入国际币种
    mouseOver1 () {
      this.langCoinFlag = true;
    },
    // 鼠标离开国际币种
    mouseLeave1 () {
      this.langCoinFlag = false;
    },
    // 选择国际币种
    jumpCoin (item) {
      this.langCoinFlag = false;
      const data = {};
      this.http(
        {
          baseURL: this.ApiSetting.marketCurrencyRateOne.baseURL,
          url: this.ApiSetting.marketCurrencyRateOne.url + item.code,
          method: this.ApiSetting.marketCurrencyRateOne.method,
        },
        data,
        { Authorization: sessionStorage.getItem("sessionID") }
      ).then(
        (res) => {
          if (res.data.statusCode === "000000") {
            this.$store.commit("internationalCurrency", item.code);
            this.$store.commit("internationalCurrencyRate", res.data.data.rate);
            this.$store.commit(
              "internationalCurrencySymbol",
              res.data.data.symbol
            );
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
    // 点击底部的logo跳转首页
    jumpHomePage () {
      this.$router.push({
        path: "/HomePage",
      });
      window.scrollTo(0, 0);
    },
    // 查询是否登录
    queryIsLogin () {
      if (sessionStorage.getItem("sessionID")) {
        this.$store.commit("isLogin", true);
      } else {
        this.$store.commit("isLogin", false);
      }
    },
    // 查询币种
    queryInternationalCurrency () {
      const data = {};
      this.http(this.ApiSetting.marketCurrencyRate, data, {
        Authorization: sessionStorage.getItem("sessionID"),
      }).then(
        (res) => {
          if (res.data.statusCode === "000000") {
            this.internationalCurrencyArr = res.data.data;
            this.$store.commit("internationalCurrency", res.data.data[0].code);
            this.$store.commit(
              "internationalCurrencyRate",
              res.data.data[0].rate
            );
            this.$store.commit(
              "internationalCurrencySymbol",
              res.data.data[0].symbol
            );
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
    // 注册
    register () {
      this.$router.push({
        path: "/register",
      });
      this.active = "";
    },
    // 选择语言
    chooseLang () {
      this.dialogVisible = true;
      this.langLoading = true;
      const data = {};
      this.http(this.ApiSetting.languageType, data, {
        Authorization: sessionStorage.getItem("sessionID"),
        lang: this.$store.state.languageType,
      }).then(
        (res) => {
          if (res.data.statusCode === "000000") {
            this.langArr = res.data.data;
          }
          this.langLoading = false;
        },
        (error) => {
          this.langLoading = false;
          console.log(error);
        }
      );
    },
    handleClose () {
      this.dialogVisible = false;
    },
    langChoose (item) {
      this.$store.commit("changeLanguageType", item.LanguageType);
      axios.defaults.headers.common["lang"] = item.LanguageType;
      this.chooseLang1(item.LanguageType);
      this.getLang();
      this.dialogVisible = false;
    },
    getLang () {
      const languageType = this.$store.state.languageType;
      const data = { type: 2, language: languageType };
      this.http(this.ApiSetting.getByLang, data).then(
        (res) => {
          if (res.data.statusCode === "000000") {
            this.langObj = res.data.data;
            this.$store.commit("changeLangObj", res.data.data);
          }
        },
        (error) => {
          console.log(error);
        }
      );

      // 获取国际区号
      const countryOptionsData = { lang: languageType };
      this.http(this.ApiSetting.countryOptions, countryOptionsData).then(
        (res) => {
          if (res.data.statusCode === "000000") {
            let option = res.data.data;
            option.forEach((item) => {
              item.phoneCode = `+${item.phoneCode}`;
            });
            this.$store.dispatch("getCountryOptionsAction", { data: option });
          }
        }
      );

      // 设置语言
      locale.use(languageType === "zh_CN" ? zh_CN : en);
    },
    chooseLang1 (languageType) {
      const data = {};
      this.http(this.ApiSetting.languageType, data, {
        Authorization: sessionStorage.getItem("sessionID"),
        lang: this.$store.state.languageType,
      }).then(
        (res) => {
          if (res.data.statusCode === "000000") {
            if (res.data.data.length !== 0) {
              res.data.data.forEach((item) => {
                if (item.LanguageType === languageType) {
                  this.$store.commit(
                    "changeLanguageContent",
                    item.LanguageContent
                  );
                  this.$store.commit("changeLanguageFace", item.LanguageFace);
                }
              });
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },

    // 介绍页方法
    tokenportClick () {
      window.open(
        "https://tokenport.io/"
      );
    },
    downloadClick () {
      // if (navigator.userAgent.includes('iPhone')) {
      //   this.$message.warning({
      //     message: this.langObj['Apple iOS app not yet available']
      //   })
      //   return
      // }
      // window.open('https://play.google.com/store/apps/details?id=com.yy.s6&hl=zh-cn')
      this.openAppMobile()
    },
    introHandleMouseOver (event, value) {
      this.introHover = value;
    },
    introHandleMouseOut () {
      this.introHover = -1;
    },
    tipHandleMouseOver (event, value) {
      this.tipActive = value;
    },
    tipHandleMouseOut () {
      this.tipActive = -1;
    },
    getLanguageTypeData () {
      this.http(this.ApiSetting.languageType, {}).then(
        (res) => {
          if (res.data.statusCode === "000000") {
            this.languageTypeData = res.data.data;
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
    langHandleMouseOver (event, value) {
      this.tipActive = value;
    },
    langHandleMouseOut () {
      this.tipActive = -1;
    },
    langClickHandle (item, value) {
      this.$store.commit("changeLanguageType", item.LanguageType);
      axios.defaults.headers.common["lang"] = item.LanguageType;
      this.$store.commit("changeLanguageContent", item.LanguageContent);
      this.$store.commit("changeLanguageFace", item.LanguageFace);
      this.getLang();
      this.tipActive = value;
    },
    signUpClickHandle (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const { pwd, ...otherForm } = this.accountInfoData
          const data = {
            registerType: this.registerType,
            pwd: this.$md5(pwd).toUpperCase(),
            ...otherForm,
          };
          this.http(this.ApiSetting.memberRegister, data).then((res) => {
            if (res.data.statusCode === "000000") {
              this.$message.success(this.langObj["注册成功"]);
              if (this.$device.mobile) {
                if (navigator.userAgent.includes('iPhone')) {
                  window.open('https://j33wj.com/lsEIv')
                } else {
                  window.open('https://play.google.com/store/apps/details?id=com.yy.s6&hl=zh-cn')
                }
                // window.open('https://play.google.com/store/apps/details?id=com.yy.s6&hl=zh-cn')
              } else {
                this.$router.push({
                  name: "login",
                  params: { username: data.registerName },
                });
              }
            }
          });
        } else {
          return false;
        }
      });
    },
    introJumpOtherPageClick (value) {
      this.closeSignUpDialog();
      this.closeScrollDialog();
      this.jumpActive(value);
      window.scrollTo(0, 0);
    },
    openSignUpDialog () {
      this.isShowSignUpDialog = true;
      this.registerType = 1;
    },
    closeSignUpDialog () {
      if (this.isShowSignUpDialog) {
        this.$refs["signUpFormRef"].resetFields();
        this.accountInfoData.inviteCode = "";
        this.isShowSignUpDialog = false;
      }
    },
    closeScrollDialog () {
      if (this.isDialogOpen) {
        this.isDialogOpen = false;
        this.emailValue = "";
      }
    },
    subscribeClickHandle () {
      if (!this.emailValue.includes("@"))
        return this.$message.warning(this.langObj["请输入正确的邮箱"]);
      if (!this.initEmailSubscribeUrl) {
        this.initEmailSubscribeUrl = this.ApiSetting.emailSubscribe.url;
      }
      const dataUrl = this.initEmailSubscribeUrl + this.emailValue;
      this.ApiSetting.emailSubscribe.url = dataUrl;
      this.http(this.ApiSetting.emailSubscribe, {}).then((res) => {
        if (res.data.statusCode === "000000") {
          this.isDialogOpen = false;
          this.emailValue = "";
          this.$message.success(this.langObj["Subscribe Success"]);
        }
      });
    },
    // 跳转页面方法
    introFooterJumpClick (value) {
      this.closeSignUpDialog();
      this.closeScrollDialog();
      const AboutUs = "AboutUs",
        GetStarted = "GetStarted",
        CryptoAcademy = "CryptoAcademy";
      switch (value) {
        case "Who we are":
          if (this.$route.name === AboutUs) {
            this.$store.dispatch("getIntroPagePathAction", { path: value });
          } else {
            this.$router.push({ name: AboutUs, params: { path: value } });
          }
          break;
        case "What we do":
          if (this.$route.name === AboutUs) {
            this.$store.dispatch("getIntroPagePathAction", { path: value });
          } else {
            this.$router.push({ name: AboutUs, params: { path: value } });
          }
          break;
        case "Our partners":
          if (this.$route.name === AboutUs) {
            this.$store.dispatch("getIntroPagePathAction", { path: value });
          } else {
            this.$router.push({ name: AboutUs, params: { path: value } });
          }
          break;
        case "Introduction":
          if (this.$route.name === GetStarted) {
            this.$store.dispatch("getIntroPagePathAction", { path: value });
          } else {
            this.$router.push({ name: GetStarted, params: { path: value } });
          }
          break;
        case "How to get verified?":
          if (this.$route.name === GetStarted) {
            this.$store.dispatch("getIntroPagePathAction", { path: value });
          } else {
            this.$router.push({ name: GetStarted, params: { path: value } });
          }
          break;
        case "Trusted by many":
          if (this.$route.name === GetStarted) {
            this.$store.dispatch("getIntroPagePathAction", { path: value });
          } else {
            this.$router.push({ name: GetStarted, params: { path: value } });
          }
          break;
        case "Highlights":
          if (this.$route.name === CryptoAcademy) {
            this.$store.dispatch("getIntroPagePathAction", { path: value });
          } else {
            this.$router.push({ name: CryptoAcademy, params: { path: value } });
          }
          break;
        case "Novice":
          if (this.$route.name === CryptoAcademy) {
            this.$store.dispatch("getIntroPagePathAction", { path: value });
          } else {
            this.$router.push({ name: CryptoAcademy, params: { path: value } });
          }
          break;
        case "Advanced":
          if (this.$route.name === CryptoAcademy) {
            this.$store.dispatch("getIntroPagePathAction", { path: value });
          } else {
            this.$router.push({ name: CryptoAcademy, params: { path: value } });
          }
          break;
        case "FAQs":
          window.open(
            `https://s6globalex.zendesk.com/hc/${this.thirdLanguageType}/categories/23956752663321-Common-problem`
          );
          break;
        case "How-to-guide":
          window.open(
            `https://s6globalex.zendesk.com/hc/${this.thirdLanguageType}`
          );
          break;
        case "Help Center":
          window.open(`https://s6globalex.zendesk.com/hc/${this.thirdLanguageType}/sections/23956752709529-Deposit-Receipt-Withdraw-Transfer`);
          break;
        case "Withdrawal Issues":
          window.open(
            `https://s6globalex.zendesk.com/hc/${this.thirdLanguageType}/articles/24669902466201--How-to-withdraw-transfer-digital-currency-APP-side-`
          );
          break;
        case "Deposit Issues":
          window.open(
            `https://s6globalex.zendesk.com/hc/${this.thirdLanguageType}/articles/23956752712729-How-to-Deposit-receive-digital-currency`
          );
          break;
        case "Common Issues":
          window.open(
            `https://s6globalex.zendesk.com/hc/${this.thirdLanguageType}/categories/23956752663321-Common-problem`
          );
          break;
        case "User Agreement":
          window.open(
            `https://s6globalex.zendesk.com/hc/${this.thirdLanguageType}/articles/24921536159129-User-Agreement-and-Privacy-Policy`
          );
          break;
        case "Privacy Policy":
          window.open(
            `https://s6globalex.zendesk.com/hc/${this.thirdLanguageType}/articles/24921536159129-User-Agreement-and-Privacy-Policy`
          );
          break;
        case "Operational Risks":
          window.open(
            `https://s6globalex.zendesk.com/hc/${this.thirdLanguageType}`
          );
          break;
        case "Announcement-Center":
          window.open(
            `https://s6globalex.zendesk.com/hc/${this.thirdLanguageType}/categories/24559411772953-Announcement-Center`
          );
          break;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "homeMobileStyle.scss";

@media screen and (max-width: 768px) {
  .page {
    position: relative;
  }
}
@media screen and(min-width:769px) {
  .page {
    min-width: 1500px;
    position: relative;
  }
}
.page-header {
  background-color: #16181d;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 1350px;
}
.page-header .left-pages {
  margin-left: 20px;
  display: flex;
  align-items: center;
}
.logo-box {
  height: 60px;
  display: flex;
  align-items: center;
}
.logo {
  width: 130px;
  height: 30px;
}
.logo:hover {
  cursor: pointer;
}
.pages-routes {
  margin-left: 45px;
}
.pages-routes ul {
  display: flex;
}
.pages-routes ul li {
  margin-right: 20px;
  height: 60px;
  line-height: 60px;
  box-sizing: border-box;
}
.pages-routes ul li span {
  color: #fff;
  opacity: 0.6;
  font-size: 14px;
}
.pages-routes ul li span:hover {
  color: #fff;
  opacity: 1;
}
.pages-routes ul .isActive {
  border-bottom: 3px solid #f7b239;
}
.pages-routes ul .isActive span {
  color: #fff;
  opacity: 1;
}
.right-menus {
  display: flex;
  align-items: center;
}
.right-menus .isActive {
  border-bottom: 3px solid #f7b239;
}
.login {
  color: #acb9db;
  font-size: 14px;
  margin-right: 20px;
  height: 60px;
  line-height: 60px;
}
.register {
  color: #333333;
  font-size: 14px;
  background: #fdd42b;
  display: inline-block;
  width: 68px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 2px;
}
.login:hover,
.register:hover {
  cursor: pointer;
}
.user-box {
  display: flex;
  align-items: center;
  height: 60px;
  position: relative;
}
.user-img {
  width: 20px;
  height: 20px;
}
.user-active {
  width: 20px;
  height: 20px;
  display: none;
}
.user-txt {
  margin-left: 9px;
  font-size: 14px;
  color: #fff;
  opacity: 0.6;
}
.download {
  margin-left: 36px;
  margin-right: 36px;
  display: flex;
  align-items: center;
  position: relative;
  height: 60px;
}
.download-father {
  display: flex;
}
.download-img {
  width: 20px;
  height: 20px;
}
.download-active {
  width: 20px;
  height: 20px;
  display: none;
}
.download-span {
  color: #a3a9b3;
  font-size: 14px;
  margin-left: 6px;
}
.download-box {
  position: absolute;
  top: 60px;
  left: -10px;
  width: 250px;
  z-index: 999;
  background: #ffffff;
  padding-top: 10px;
  padding-bottom: 15px;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.08);
  display: none;
}
.download-txt {
  font-size: 12px;
  color: #999999;
  margin-left: 18px;
  width: 220px;
  display: inline-block;
}
.download-phone-box {
  display: flex;
  margin-top: 15px;
}
.download-phone-father {
  display: flex;
  flex-direction: column;
}
.download-phone {
  margin-left: 16px;
  display: flex;
  align-items: center;
}
.download-phone-two {
  margin-top: 16px;
}
.download-phone-img {
  width: 28px;
  height: 28px;
}
.download-phone-txt {
  font-size: 14px;
  color: #333333;
}
.download-erweima {
  width: 72px;
  height: 72px;
  margin-left: 37px;
}
.lang-box {
  display: flex;
  align-items: center;
  position: relative;
  width: 83px;
}
.lang-father {
  display: flex;
  align-items: center;
  height: 60px;
}
.lang-internationalCurrency-img {
  width: 20px;
  height: 20px;
}
.lang-span {
  color: #a3a9b3;
  font-size: 14px;
}
.lang-img {
  width: 20px;
  height: 20px;
  margin-left: 2px;
}
.lang-active {
  width: 20px;
  height: 20px;
  margin-left: 2px;
  display: none;
}
.lang-img-sel {
  width: 16px;
  height: 16px;
}
.lang-box-sel {
  display: flex;
  align-items: center;
  height: 60px;
}
.lang-span-sel {
  color: #fff;
  opacity: 0.6;
  font-size: 14px;
  margin-left: 10px;
  margin-right: 20px;
}
.page-footer {
  background-color: #0d1327;
  padding: 50px 0 20px;
}
.first-content {
  display: flex;
  justify-content: center;
}
.page-footer-content {
  display: flex;
  justify-content: space-between;
  width: 1000px;
}
.logo-content {
  display: flex;
  flex-direction: column;
}
.logo-img {
  width: 196px;
  height: 46px;
}
.logo-link {
  color: #fff;
  margin-top: 16px;
}
.logo-jump {
  width: 21px;
  height: 18px;
  margin-top: 24px;
}
.tg-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.erweima {
  width: 80px;
  height: 80px;
}
.join {
  font-size: 12px;
  color: #fff;
  margin-top: 12px;
}
.text-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.sub-title {
  font-size: 16px;
  color: #fff;
  opacity: 0.6;
  margin-bottom: 17px;
}
.sub-txt {
  font-size: 12px;
  color: #fff;
  margin: 0 0 17px;
}
.logo-img:hover,
.logo-jump:hover,
.sub-txt:hover {
  cursor: pointer;
}
.user-more {
  background: #ffffff;
  padding: 10px 0;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.08);
  position: absolute;
  top: 60px;
  left: -15px;
  z-index: 9999;
}
.user-line {
  display: flex;
  align-items: center;
  padding: 0 20px;
  height: 40px;
}
.menu-icon {
  color: #909399;
}
.user-line:hover .menu-icon {
  color: #303133;
}
.user-line-txt {
  font-size: 14px;
  color: #909399;
  margin-left: 10px;
  white-space: nowrap;
}
.user-line:hover {
  background: #f3f7fc;
}
.user-line:hover .user-line-txt {
  color: #303133;
}
.lang-more {
  width: 103px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.08);
  position: absolute;
  top: 60px;
  left: 0px;
  z-index: 999;
  padding: 10px 0;
  overflow-y: scroll;
  max-height: 500px;
}
.lang-line {
  display: flex;
  align-items: center;
  padding-left: 18px;
  line-height: 40px;
}
.lang-line-img {
  width: 24px;
  height: 24px;
}
.lang-line-txt {
  font-size: 14px;
  color: #000000;
  margin-left: 8px;
}
.download:hover .download-box {
  display: block;
}
.download:hover .download-span {
  color: #fff;
}
.download:hover .download-img {
  color: #fdd42b;
  display: none;
}
.download:hover .download-active {
  display: block;
}
.download-box:hover .download-box {
  display: block;
}
.lang-box:hover .lang-more {
  display: block;
}
.lang-box:hover .lang-span {
  color: #fff;
}
.lang-box:hover .lang-img {
  display: none;
}
.lang-box:hover .lang-active {
  display: block;
}
.lang-line:hover {
  background: #f6f6f6;
}
.user-box:hover .user-txt {
  color: #fff;
  opacity: 1;
}
.user-box:hover .user-active {
  display: block;
}
.user-box:hover .user-img {
  display: none;
}
.user-line:hover,
.user-box:hover,
.download-father:hover,
.lang-father:hover,
.lang-line:hover,
.pages-routes ul li:hover,
.lang-box-sel:hover {
  cursor: pointer;
}
.active-second {
  color: #303133;
}
/* 设置滚动条的样式 */
::-webkit-scrollbar {
  width: 0px;
  height: 1px;
}
/* 滚动槽 */
::-webkit-scrollbar-track {
  border-radius: 5px;
}
/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
}
.lang-box-pop {
  display: flex;
  flex-wrap: wrap;
}
.lang-box-flex {
  display: flex;
  align-items: center;
  width: 25%;
  cursor: pointer;
  padding: 10px;
}
.lang-logo {
  width: 28px;
  height: 28px;
}
.lang-span {
  margin-left: 10px;
}

/* 介绍顶部栏 */

p {
  margin: 0;
}
.intro-header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  min-width: 1450px;
  height: 80px;
  display: flex;
  justify-content: center;
  background: #fff;
  color: #505050;
  font-size: 18px;
  font-weight: 600;
  z-index: 1009;
  span {
    font-weight: 600;
  }
  .header-content {
    min-width: 1200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .header-logo {
    /* width: 180px;
    height: 43px; */
    margin-right: 106px;
    color: #f5bd22;
    cursor: pointer;
  }
  .content-right {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .intro-page {
    flex: 1;
    display: flex;
    justify-content: space-between;
    .page-name {
      position: relative;
      display: flex;
      margin-right: 30px;
      cursor: pointer;
      user-select: none;
      span {
        white-space: nowrap;
      }

      svg {
        display: none;
        position: absolute;
        right: -9px;
        top: 50%;
        transform: translate(100%, -50%);
      }

      &.intro-active {
        color: #f3b402;
        &::after {
          content: "";
          position: absolute;
          bottom: -6px;
          left: 50%;
          width: 50px;
          height: 3px;
          background: #f3b402;
          transform: translateX(-50%);
        }

        svg {
          display: inline-block;
        }
      }
      &.introHover {
        svg {
          display: inline-block;
        }
      }
    }
    .page-name:last-child {
      margin-right: 60px;
    }
  }
  .start-btn {
    padding: 13px 26px;
    border-radius: 40px;
    background: #f3b402;
    margin-right: 38px;
    /* margin-left: 60px; */
    cursor: pointer;
    user-select: none;
    span {
      font-weight: bold;
    }
  }
  .switch-lang {
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
    &:hover {
      color: #f3b402;
    }
    .lang-wrapper {
      display: flex;
      align-items: center;
    }
    .lang-text {
      margin-left: 14px;
    }
  }
  .tokenport {
    margin-left: 28px;
    cursor: pointer;
    user-select: none;
  }
  .intro-download {
    margin-left: 28px;
    width: 36px;
    height: 36px;
    cursor: pointer;
    user-select: none;
    > img {
      width: 100%;
      height: 100%;
    }
  }

  &.scroll-style {
    background: #f3b402;
    .header-logo {
      color: #fff;
    }
    .intro-page {
      .page-name {
        &.intro-active {
          color: #fff;
          &::after {
            background: #fff;
          }
        }
        svg {
          color: #fff;
        }
      }
    }
    .start-btn {
      background: #fff;
    }
    .switch-lang {
      &:hover {
        color: #fff;
      }
    }
  }
}
.info-tip {
  position: relative;
  font-size: 16px;
  font-weight: 600;
  color: #505050;
  margin: 20px 14px 22px 25px;
  .indicator {
    opacity: 0;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: -8px;
      width: 3px;
      height: 16px;
      background: #f3b402;
    }
  }
  .tip-content {
    position: relative;
    /* margin-left: 14px; */
    cursor: pointer;
    user-select: none;
    .tip-list {
      margin-left: 10px;
      margin-top: 2px;
      li::before {
        content: "";
        width: 6px;
        height: 6px;
        display: inline-block;
        border-radius: 50%;
        background: #505050;
        vertical-align: middle;
        margin-right: 10px;
      }
    }
    &.tip-active {
      color: #f3b402;
      .indicator {
        opacity: 1;
      }
      .tip-list {
        li::before {
          background: #f3b402;
        }
      }
    }
  }
  .tip-content + .tip-content {
    margin-top: 12px;
  }
}

/* 介绍页底部栏 */
.footer-wrapper {
  display: flex;
  justify-content: center;
  background: #e9e9e9;
}
.intro-footer {
  /* position: absolute;
  left: 0;
  right: 0;
  bottom: 0; */
  /* height: 540px; */
  min-width: 1200px;
  color: #373737;
  font-size: 14px;
  padding: 40px 0 38px;
  .footer-content {
    display: flex;
    justify-content: space-between;
    /* margin-top: 74px; */
    /* padding: 0 200px; */
    .content-left {
      display: flex;
      .footer-info {
        p {
          height: 19px;
          line-height: 19px;
          font-weight: 400;
          cursor: pointer;
          user-select: none;
        }
        p + p {
          margin-top: 12px;
        }
        .info-title {
          font-size: 16px;
          font-weight: bold;
          margin-bottom: 16px;
        }
      }
      .footer-info + .footer-info {
        margin-left: 84px;
      }
    }
    .content-right {
      width: 197px;
      text-align: center;
      .footer-logo {
        color: #f3b402;
      }
      .footer-tip {
        margin-top: 12px;
        font-weight: 600;
        cursor: pointer;
        user-select: none;
      }
    }
  }
  .footer-copyright {
    .copyright-text {
      margin-top: 68px;
      text-align: center;
      font-weight: bold;
      cursor: pointer;
      user-select: none;
    }
  }
}
/* 介绍页弹窗 */
.intro-dialog-wrapper {
  position: fixed;
  left: 0;
  right: 0;
  top: 80px;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
  z-index: 999;
  .intro-dialog-content {
    position: relative;
    height: 576px;
    background: #fff;
    border-radius: 30px;
    display: flex;
    .close-btn {
      position: absolute;
      right: 24px;
      top: 24px;
      padding: 10px;
      box-sizing: border-box;
      cursor: pointer;
      .close-img {
        width: 16px;
        height: 16px;
        vertical-align: top;
      }
    }
    .content-left {
      position: relative;
      .sign-up-bg-wrapper {
        width: 360px;
        height: 100%;
        .sign-up-bg-img {
          width: 100%;
          height: 100%;
        }
      }
      .left-info {
        position: absolute;
        left: 0;
        /* right: 0; */
        top: 0;
        bottom: 0;
        width: 360px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 55px;
        box-sizing: border-box;
        .info-logo {
          width: 96px;
          height: 84px;
          margin-top: 86px;
          .logo-img {
            width: 100%;
            height: 100%;
          }
        }
        .info-title {
          margin-top: 46px;
          font-size: 29px;
          font-weight: bold;
          color: #ffffff;
          user-select: none;
        }
        .info-text {
          margin-top: 30px;
          font-size: 18px;
          font-weight: 400;
          color: #505050;
          text-align: center;
          user-select: none;
        }
        .sign-in-btn {
          margin-top: 42px;
          width: 216px;
          line-height: 54px;
          border-radius: 36px;
          border: 1px solid #ffffff;
          text-align: center;
          cursor: pointer;
          user-select: none;
          > span {
            font-size: 16px;
            font-weight: bold;
            color: #ffffff;
          }
        }
      }
    }
    .content-right {
      width: 576px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .right-title {
        margin-top: 54px;
        line-height: 31px;
        font-size: 28px;
        font-weight: bold;
        color: #f3b402;
        user-select: none;
      }
      .account-imgs {
        display: flex;
        align-items: flex-end;
        margin-top: 36px;
        margin-bottom: 20px;
        .account-img {
          position: relative;
          color: #d9d9d9;
          cursor: pointer;
        }
        .email-img {
          position: relative;
          margin-left: 32px;
          color: #d9d9d9;
          cursor: pointer;
        }
        .account-active {
          color: #f3b402;
          &::after {
            content: "";
            position: absolute;
            left: 50%;
            bottom: -4px;
            transform: translateX(-50%);
            width: 12px;
            height: 4px;
            background: #f3b402;
            border-radius: 36px;
          }
        }
      }
      .sign-up-input {
        display: flex;
        flex-direction: column;
        /deep/.el-input {
          width: 288px;
          .el-input__inner {
            height: 54px;
            line-height: 54px;
            font-size: 14px;
            font-weight: 500;
            border: 1px solid #e9e9e9;
            color: #505050;
          }
          .el-input__inner::placeholder {
            color: #d9d9d9;
          }
          .el-input__icon {
            font-size: 18px;
          }
        }
        .el-form-item {
          margin-bottom: 0;
        }
        .el-form-item + .el-form-item {
          margin-top: 20px;
        }
      }
      .sign-up-btn {
        margin-top: 24px;
        width: 216px;
        line-height: 54px;
        border-radius: 36px;
        background: #f3b402;
        text-align: center;
        cursor: pointer;
        user-select: none;
        > span {
          font-size: 16px;
          font-weight: bold;
          color: #505050;
        }
      }
    }
  }
}
/* 介绍页滚动后弹窗 */
.dialogBox {
  position: relative;
  width: 764px;
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 44px 0 44px 66px;
  border-radius: 20px;
  box-sizing: border-box;
  .top-img {
    position: absolute;
    top: -143px;
    left: 290px;
    width: 280px;
    height: 280px;
    .plane-img {
      width: 100%;
      height: 100%;
    }
  }
  .close-btn {
    position: absolute;
    right: 24px;
    top: 24px;
    padding: 10px;
    box-sizing: border-box;
    cursor: pointer;
    .close-img {
      width: 20px;
      height: 20px;
      vertical-align: top;
    }
  }
  .dialogTitle {
    width: 100%;
    /* height: 100px; */
    font-size: 40px;
    font-weight: 1000;
    color: #f3b402;
    line-height: 47px;
    user-select: none;
    .dialogTitleColo {
      color: #505050;
      font-weight: 1000;
      /* line-height: 88px; */
    }
  }
  .dialogContent {
    /* width: 723px; */
    /* height: 37px; */
    font-size: 16px;
    font-weight: 600;
    color: #505050;
    line-height: 19px;
    margin-top: 12px;
    user-select: none;
    /* text-align: left; */
  }
  .dialogImg {
    width: 364px;
    height: 86px;
    margin-top: 36px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .markedWords {
    /* width: 345px; */
    /* height: 30px; */
    font-size: 16px;
    font-weight: 300;
    color: #505050;
    line-height: 19px;
    margin-top: 53px;
    margin-left: 8px;
    user-select: none;
  }

  /deep/.el-input__inner {
    width: 350px;
    height: 42px;
    background: #ededed;
    border-radius: 50px 50px 50px 50px;
    opacity: 1;
    margin-top: 16px;
    padding-left: 29px;

    line-height: 29px;
    font-size: 14px;
    border: 0;
    &::placeholder {
      font-weight: 300;
      font-size: 14px;
      color: #505050;
    }
  }
  /deep/ .el-icon-close:before {
    font-size: 25px;
    font-weight: 600;
    color: #505050;
  }
  /deep/.el-button {
    border: 0;
    span {
      font-weight: bold;
    }
  }
  .bottom {
    display: flex;
    /* width: 846px; */
    align-items: flex-end;
    /* justify-content: space-between; */
    .right {
      margin-left: 18px;
      .button {
        width: 130px;
        height: 42px;
        background: #f3b402;
        border-radius: 34px;
        opacity: 1;
        color: #505050;
        font-size: 14px;
        font-weight: bold;
      }
    }
  }
}
/deep/.menu-icon {
  font-size: 18px;
}
</style>
<style lang="scss">
.intro-tooltip {
  border: 0 !important;
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.1),
    -10px -10px 30px rgba(0, 0, 0, 0.1);
  .popper__arrow {
    border-bottom-color: #fff !important;
  }
}
</style>

import store from '../store/store.js'
function timeChange (timeValue) {
  timeValue = new Date(timeValue).getTime()
  var returnTime = ''
  const date = new Date(timeValue)
  const today = new Date()
  const dateYear = date.getFullYear()
  const toYear = today.getFullYear()
  const dateMonth = date.getMonth()
  const toMonth = today.getMonth()
  const dateDate = date.getDate()
  const toDate = today.getDate()
  if (dateYear === toYear) { // 今年
    if (dateMonth === toMonth) {
      if (dateDate === toDate) {
        returnTime = formatTime(timeValue).substr(11, 5)
      } else {
        if (dateDate === toDate -1) {
          returnTime = store.state.langObj['昨天'] + formatTime(timeValue).substr(11, 5)
        } else {
          returnTime = formatTime(timeValue).substr(5, 11)
        }
      }
    } else {
      returnTime = formatTime(timeValue).substr(5, 11)
    }
  } else { // 不属于今年
    returnTime = formatTime(timeValue).substr(0, 16)
  }
  return returnTime
}
function formatTime (date) {
  var t = getTimeArray(date)
    return [t[0], t[1], t[2]].map(formatNumber).join('/') + ' ' + [t[3], t[4], t[5]].map(formatNumber).join(':')
  }
  
function getTimeArray (date) {
  date = new Date(date)
  var year = date.getFullYear()
  var month = date.getMonth() + 1
  var day = date.getDate()
  var hour = date.getHours()
  var minute = date.getMinutes()
  var second = date.getSeconds()
  return [year, month, day, hour, minute, second].map(formatNumber)
}

// 转化日期 如2018-7-6 -->(2018-07-06)
function formatNumber (n) {
  n = n.toString()
  return n[1] ? n : '0' + n
}
export {
  timeChange
}




module.exports = {
  common: {
      login: '登录',
      register: '注册',
      loginregister: "登录/注册",
      logout: '退出登录',
      tip: '温馨提示',
      logintip: '请先登录',
      expect: '敬请期待!',
      delete: '确定删除吗?',
      nodata: '暂无记录',
      set: '设置',
      update: '修改',
      slogan: "严选全球优质数字资产",
      subslogan: "坚持服务101年",
      more: "更多",
      please: "请先",
      pleaseselect: "请选择",
      searchplaceholder: "输入币种名称搜索",
      searchplaceholderswap: "输入合约名称搜索",
      loading: "加载中...",
      close: "关闭",
      ok: "确定"
  },
  header: {
      market: 'market',
      coincurrencytrading: '币币交易',
      ustandardcontract: 'U本位合约',
      currencystandardcontract: '币本位合约',
      financialservices: '金融理财',
      login: '登录',
      register: '注册',
      usercenter: "用户中心",
      grzl: '个人总览',
      zcgl: '资产管理',
      jygl: '交易管理',
      lcgl: '理财管理',
      tgzx: '推广中心',
      kqzx: '卡券中心',
      zd: '账单',
      out: '退出登录',
      appdownlaod: 'APP下载',
      downloadapp: "扫码下载iOS&Android APP",
      lang: '中文简体'
  },
  exchange: {
      coin: '币种',
      symbol:"交易对",
      lastprice: '最新价',
      daychange: '24h涨跌',
      market: '市场',
      favorite: '收藏',
      do_favorite: '已收藏',
      cancel_favorite: '取消收藏',
      type: "类型",
      dealamount: "成交金额",
      high: '高',
      low: '低',
      vol: '24H量',
      buy: '买入',
      sell: '卖出',
      limited_price: '限价',
      market_price: '市价',
      fees_rate: '费率',
      balance: '资产',
      or: '或',
      starttrade: '开始交易',
      canuse: '可用',
      recharge: '充币',
      buyprice: '买入价',
      buynum: '买入量',
      amount: '交易额',
      buyin: '买入',
      disablebuyin: "禁止市价买入",
      buytip: '以市场上最优价格买入',
      sellprice: '卖出价',
      sellnum: '卖出量',
      sellout: '卖出',
      disablesellout: "禁止市价卖出",
      selltip: '以市场上最优价格卖出',
      curdelegation: '当前委托',
      hisdelegation: '委托历史',
      realtransaction: '实时成交',
      num: '数量',
      price: '价格',
      direction: '方向',
      time: '时间',
      stall: '档位',
      total: '累计',
      traded: '已成交',
      action: '操作',
      undo: '撤单',
      delegationnum: '委托量',
      done: '已成交',
      status: '状态',
      finished: '已完成',
      canceled: '已取消',
      parted: "部分成交",
      tip: '提示',
      buyamounttip: '请输入买入量',
      buyamounttipwarning: '买入数量不能高于',
      success: '提交成功',
      pricetip: '请输入交易额',
      pricetipwarning: '交易额不能高于',
      sellamounttip: '请输入卖出量',
      sellamounttipwarning: '卖出数量不能高于',
      sellpricetip: '请输入卖出价格',
      sellpricetipwarning: '卖出价格不能高于',
      undotip: '是否确认撤单？',
      cancelsuccess: "撤单成功！",
      marketprice: '市价',
      expand: {
          time: '时间',
          price: '价格',
          amount: '数量',
          fee: '手续费',
      },
      realtime: '分时',
      kline: "K线图",
      depth: "深度图",
      publishstate0: "活动即将开始，请稍候...",
      publishstate1: "活动即将结束，请尽快交易...",
      publishstate2: "活动订单盘整中，请稍候...",
      publishstate3: "盘整结束，请自由交易...",
      publishdetail: "活动规则详情",
      publishprice: "发行价",
      publishamount: "总量",
      publishcounttxt0: "距活动开始还剩",
      publishcounttxt1: "距离活动结束还剩",
      publishcounttxt2: "距离清盘结束还剩",
      publishcounttxt3: "活动已结束",
      dateTimeDay: "天",
      dateTimeHour: "时",
      dateTimeMinutes: "分",
      dateTimeSeconds: "秒",
      moredetail: "更多详情"
  },
  footer: {
      join: '加入官方telegram频道',
      gsjj: '关于',
      notice: '公告中心',
      bzzx: '帮助中心',
      tk: '条款',
      fwxy: '服务协议',
      ysxy: '隐私协议',
      mzsm: '免责声明',
      other: '其他',
      rate: '费率标准',
      api: 'API'
  },
  market: {
      volume: '24H vooo',
      hq: '行情',
      keyword: '请输入关键词',
      zx: '自选',
      bbsc: '币币市场',
      symbol: '交易对',
      close: '最新价',
      chg: '24小时涨跌幅',
      high: '24小时最高',
      low: '24小时最低',
      operate: '操作',
      detail: '详情',
      sq: '收起',
      officeSiteUrl: '官网',
      whitePagerUrl: '白皮书',
      blockExplorerUrl: '区块查询',
      coinName: '币种名称',
      publishTime: '发行时间',
      totalSupply: '发行总量',
      circulationSupply: '流通总量',
      price: '众筹价格',
      
  },
  login: {
     login: {
      title: '登录',
      username: '邮箱/手机号/用户名',
      placeholder: '请输入',
      noRegister: '没有账户？去',
      registerName: '注册',
      forgetPwd: '忘记密码？',
      validateUserName: '请输入邮箱/手机号/用户名',
      validatePwd: '请输入密码'
     },
     forgetPwd: {
      title: '重置密码',
      username: '邮箱/手机号/用户名',
      placeholder: '请输入',
      btnNext: '下一步',
      btnCancel: '取消',
      securityVerification: '安全验证',
      mailboxVerification: '邮箱验证',
      jobDescription: '工作说明',
      getVerificationCode: '获取验证码',
      mobileVerification: '手机号验证',
      googleAuthenticator: '谷歌验证',
      setNewPassword: '设置新密码',
      newPassword: '新密码',
      newPwdPlaceholder: '请输入6-20位密码',
      confirmPassword: '确认密码',
      againPlaceholder: '请再次输入',
      determine: '确定',
      pleaseVerificationCode: '请输入验证码',
      sixVerificationCode: '请输入6位数字验证码',
      pleasePwdVerificationCode: '请输入密码',
      pwdTipsVerificationCode: '密码不能为纯英文或数字，不能包含空格',
      confirmPwdVerificationCode: '请输入确认密码',
      twicePwdVerificationCode: '两次输入密码不一致',
      pleaseUsernameVerificationCode: '请输入邮箱/手机号/用户名',
      second: '秒',
      errorTip: '账号未绑定谷歌或邮箱，无法重置密码~',
     }
  }
}

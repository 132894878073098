import 'amfe-flexible';

const designWidth = 390; // 设计稿宽度，单位为px
const remUnit = 100; // 1rem等于的像素值

function setRemUnit() {
  const ratio = window.innerWidth / designWidth;
  document.documentElement.style.fontSize = `${ratio * remUnit}px`;
}

setRemUnit();
window.addEventListener('resize', setRemUnit);